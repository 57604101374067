import actions from './actions';
import * as client from '../../utils/client';

export const getList = (data, loading = true) => {
	return (dispatch) =>
		client.exec({
			url: '/dealerships',
			data,
			callback: function (res) {
				dispatch(actions.getList(res));
			},
			loading,
		});
};
export const createOne = (data, loading = true) => {
	return (dispatch) =>
		client.exec({
			type: 'post',
			url: '/dealership/create',
			data,
			callback: function (res) {
				dispatch(actions.createOne(res));
			},
			loading,
		});
};

export const getOne = (id, data, loading = true) => {
	return (dispatch) =>
		client.exec({
			url: '/dealership/' + id,
			data,
			callback: function (res) {
				dispatch(actions.getOne(res));
			},
			loading,
		});
};

export const editOne = (id, data, loading = true) => {
	return (dispatch) =>
		client.exec({
			type: 'put',
			url: '/dealership/' + id,
			data,
			callback: function (res) {
				dispatch(actions.editOne(res));
			},
			loading,
		});
};

export const uploadCsv = (data, loading = true) => {
	return (dispatch) =>
		client.exec({
			type: 'post',
			url: '/dealership/csv',
			data,
			callback: function (res) {
				dispatch(actions.uploadCsv(res));
			},
			loading,
		});
};

export const downloadCsv = () => {
	return () =>
		client.download(
			'/dealership/csv',
			{},
			function (res) {
			const url = window.URL.createObjectURL(new Blob([res]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `PAVE_dealerships_${Date.now()}.csv`);
			document.body.appendChild(link);
			link.click();
		});
};
