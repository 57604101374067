import React from 'react';
import DateTime from '../../ui/components/DateTime';
import { uid } from '../../../utils/string';

const RequestTableUser = props => {
	const { items } = props;

	const list =
		Array.isArray(items) &&
		items.map(item => {
			const index = uid();
			const dealership = item.dealership || {};
			const rep_manager = item.rep_manager || {};
			const rep = item.rep || {};
			let status = {};
			if (item.status === 'COMPLETED') {
				status.class = 'success';
				status.label = 'Complete';
			} else if (item.status === 'IN-QUEUE') {
				status.class = 'secondary';
				status.label = 'Not Started';
			} else {
				status.class = 'info';
				status.label = 'In Progress';
			}
			return (
				<tr key={index}>
					<td>
						<span
							className={`small text-uppercase text-bold text-${status.class}`}
						>
							{status.label}
						</span>
					</td>
					<td>{dealership.dealer_name || '--'}</td>
					<td>{dealership.address || '--'}</td>
					<td>
						{rep_manager.username
							? `${rep_manager.firstname} ${rep_manager.lastname}`
							: '--'}
					</td>
					<td>
						{rep.username
							? `${rep.firstname} ${rep.lastname}`
							: '--'}
					</td>
					<td>
						<DateTime
							value={item.todo_date}
							format="ddd, MMM DD, YYYY"
						/>
					</td>
					<td>{item.time_of_date}</td>
					<td>{item.number_vehicles}</td>
					<td>{item.contact_name}</td>
					<td>{item.contact_number}</td>
				</tr>
			);
		});

	return (
		<div className="table-responsive">
			<table id="Request_table" className="table table-vmid">
				<thead>
					<tr>
						<th>Status</th>
						<th>Dealership</th>
						<th>Address</th>
						<th>Assigner</th>
						<th>Rep</th>
						<th>Date</th>
						<th>Time</th>
						<th># of Vehicles</th>
						<th>Contact Name</th>
						<th>Contact Number</th>
					</tr>
				</thead>
				<tbody>{list}</tbody>
			</table>
		</div>
	);
};

export default RequestTableUser;
