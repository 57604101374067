import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOne, editOne, editOneRole, activeDealership } from '../operations';
import { setResponse } from '../../ui/operations';
import { getList as getDealerships } from '../../dealerships/operations';
import RepForm from '../components/RepForm';
import RepDealershipTable from '../components/RepDealershipTable';
import RepEditFields from '../settings/RepEditFields';
import Paginator from '../../ui/components/Paginator';
import InputText from '../../ui/components/InputText';
import { hasErrors, isPassword } from '../../../utils/validate';
import _pick from 'lodash/pick';

const defaultFields = ['firstname', 'lastname', 'email', 'username'];

export const RepView = props => {
	const {
		rep,
		dealership,
		getOne,
		editOne,
		editOneRole,
		activeDealership,
		getDealerships,
		setResponse
	} = props;
	const { id } = props.match.params;
	const [item, setItem] = useState(Array);
	const [accountInfo, setAccountInfo] = useState(Object);
	const [accountPwd, setAccountPwd] = useState(String);
	const [dealerships, setDealerships] = useState(Array);
	const [pagination, setPagination] = useState(Object);
	const [filters, setFilters] = useState(Object);
	const [activeDealerships, setActiveDealerships] = useState(Array);
	const [errors, setErrors] = useState(Object);
	const fields = RepEditFields;

	useEffect(() => {
		getOne(id);
	}, [id, getOne]);

	useEffect(() => {
		if (accountInfo.role === 'REP') {
			getDealerships();
		}
	}, [accountInfo.role, getDealerships]);

	useEffect(() => {
		setItem(rep.current);
		setAccountInfo(rep.current);
		setActiveDealerships(rep.current.dealerships || []);
	}, [rep, setItem]);

	useEffect(() => {
		setDealerships(dealership.list.data);
		setPagination(
			_pick(dealership.list, ['page', 'pages', 'count', 'limit'])
		);
	}, [dealership, setDealerships, setPagination]);

	const onChangeInput = e => {
		setAccountInfo({
			...accountInfo,
			[e.target.name]: e.target.value
		});
	};

	const onEditAccount = async e => {
		e.preventDefault();
		const validate = hasErrors(fields, accountInfo);
		if (validate.status) {
			setErrors(validate.list);
		} else {
			setErrors({});
			const success = editOne(id, _pick(accountInfo, defaultFields));
			if (success) {
				setResponse({
					response: {
						status: 200,
						message: 'All changes has been saved.'
					}
				});
			}
		}
	};

	const onChangeRole = async e => {
		e.preventDefault();
		const role = accountInfo.role;
		if (['REP_MANAGER', 'REP'].includes(role)) {
			const success = await editOneRole(id, { role });
			if (success) {
				setResponse({
					response: {
						status: 200,
						message: 'Account role has been set to ' + role + '.'
					}
				});
			}
		}
	};

	const onActiveChange = async e => {
		const value = e.target.value;
		const checked = e.target.checked;
		const type = checked ? 'assign' : 'retake';
		if (value && accountInfo.id) {
			const success = await activeDealership(value, type, {
				user_id: accountInfo.id
			});
			if (success) {
				getOne(id);
			}
		}
	};

	const onChangePaginator = ({ page, limit }) => {
		setPagination({ ...pagination, page, limit });
		getDealerships({ page, limit });
	};

	const onChangeFilter = e => {
		setFilters({ searchKey: e.target.name, search: e.target.value });
	};

	const onFilter = e => {
		e.preventDefault();
		const { searchKey, search } = filters;
		if (searchKey && search) {
			getDealerships({ searchKey, search });
		} else {
			const { page, limit } = setPagination;
			getDealerships({ page, limit });
		}
	};

	const onChangePassword = e => {
		setAccountPwd(e.target.value);
	};

	const onSetPassword = async e => {
		e.preventDefault();
		if (accountPwd && isPassword(accountPwd)) {
			setErrors({});
			setAccountPwd('');
			const success = editOne(id, { password: accountPwd });
			if (success) {
				setResponse({
					response: {
						status: 200,
						message: 'New password has been set.'
					}
				});
			}
		} else {
			setErrors({
				new_password:
					'New password should have 6-20 characters, which contain at least one numeric digit, one uppercase and one lowercase letter.'
			});
		}
	};

	return (
		<section id="Rep_view">
			<div className="heading">
				<h1>
					{item.username ? `Account: ${item.username}` : ''}
					{item.status === 'ACTIVE' ? (
						<span className="text-success ml-2">ACTIVE</span>
					) : (
						<span className="text-danger ml-2">INACTIVE</span>
					)}
				</h1>
				<ul className="buttons">
					<li>
						<Link to="/reps" className="btn btn-primary">
							<i className="la la-lg la-angle-left"></i>
							<span className="d-none d-sm-inline">Go Back</span>
						</Link>
					</li>
				</ul>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-lg-3">
						<div className="card mb-3">
							<div className="card-body">
								<RepForm
									fields={fields}
									errors={errors}
									accountInfo={accountInfo}
									onChangeInput={onChangeInput}
								/>
								<button
									onClick={onEditAccount}
									className="btn btn-block btn-primary mt-3"
								>
									Change Information
								</button>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<RepForm
									fields={[
										{
											key: 'role',
											label: 'Role',
											required: true,
											type: 'select',
											list: [
												{
													key: 'REP_MANAGER',
													label: 'Dealer Manager'
												},
												{
													key: 'REP',
													label: 'Dealer Rep'
												}
											]
										}
									]}
									errors={errors}
									accountInfo={accountInfo}
									onChangeInput={onChangeInput}
								/>
								<button
									onClick={onChangeRole}
									className="btn btn-block btn-outline-primary mb-3"
								>
									Set New Role
								</button>
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-body">
								<div className="form-group">
									<InputText
										name="new_password"
										placeholder="Enter new password..."
										value={accountPwd || ''}
										onChange={onChangePassword}
										error={errors.new_password}
									/>
								</div>
								<button
									onClick={onSetPassword}
									className="btn btn-block btn-outline-primary mt-3"
								>
									Set New Password
								</button>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-9">
						{accountInfo.role === 'REP' && (
							<div className="card">
								<div className="card-header">
									<h2 className="card-title">
										<span>Set Account Dealerships</span>
									</h2>
								</div>
								<div className="card-body">
									<Paginator
										{...pagination}
										action={onChangePaginator}
									/>
									<div className="row mb-3">
										<div className="col-12 col-md-4 col-lg-3">
											<div className="input-group">
												<input
													type="text"
													name="dealer_name"
													className="form-control"
													placeholder="Search dealership..."
													value={filters.search || ''}
													onChange={onChangeFilter}
													onKeyPress={e => {
														if (e.key === 'Enter') {
															onFilter(e);
														}
													}}
												/>
												<div className="input-group-append">
													<button
														className="btn btn-outline-primary"
														onClick={onFilter}
													>
														<i className="la la-lg la-search"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
									<RepDealershipTable
										items={dealerships}
										dealerships={activeDealerships}
										onChange={onActiveChange}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	rep: state.rep,
	auth: state.auth,
	dealership: state.dealership
});

const mapDispatchToProps = {
	getOne,
	editOne,
	editOneRole,
	activeDealership,
	getDealerships,
	setResponse
};

export default connect(mapStateToProps, mapDispatchToProps)(RepView);
