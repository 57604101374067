import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSession, getLicense } from '../operations';
import { getList as getDealerships } from '../../dealerships/operations';
import * as client from '../../../utils/client';

// const randomString = length => {
// 	var result = '';
// 	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
// 	var charactersLength = characters.length;
// 	for (var i = 0; i < length; i++) {
// 		result += characters.charAt(
// 			Math.floor(Math.random() * charactersLength)
// 		);
// 	}
// 	return result;
// };

export const SessionSendResults = props => {
	const {
		session,
		dealership,
		getSession,
		getLicense,
		getDealerships
	} = props;
	const { sessionKey } = props.match.params;
	const [results, setResults] = useState({});
	const [newManheimId, setNewManheimId] = useState(String);
	const [sendEnable, setSendEnable] = useState(false);
	const [filterSearchDealer, setfilterSearchDealer] = useState(Object);
	const [showListSearchDealer, setshowListSearchDealer] = useState(false);
	const [selectDealerships, setSelectDealerships] = useState(Array);
	const [apiResponse, setApiResponse] = useState(Object);

	const onSetDealership = id => {
		setNewManheimId(id);
		setshowListSearchDealer(false);
	};

	const onChangeFilterSearchDealer = e => {
		e.preventDefault();
		setfilterSearchDealer({
			searchKey: e.target.name,
			search: e.target.value
		});
		setshowListSearchDealer(true);
		getDealerships({
			searchKey: 'dealer_name',
			search: e.target.value,
			limit: 25
		});
	};

	const onSendNewResult = async e => {
		e.preventDefault();
		if (session.license && session.license.options) {
			const { muvit_url, muvit_integration } = session.license.options;
			if (muvit_integration) {
				console.log(results);
				try {
					await client.exec({
						type: 'post',
						url: '/httpclient/muvit',
						data: {
							endpoint: muvit_url,
							method: 'POST',
							payload: results,
							session_key: sessionKey
						},
						callback: function(resp) {
							setSendEnable(false);
							setApiResponse({
								status: 'success',
								messsage: resp
							});
						},
						loading: true
					});
				} catch (err) {
					setApiResponse({ status: 'error', messsage: err.message });
				}
			}
		}
	};

	useEffect(() => {
		const data =
			dealership.list && dealership.list.data ? dealership.list.data : [];
		let list = [];
		for (let i = 0; i < data.length; i++) {
			const item = data[i];
			list.push({
				key: item.id,
				label: item.dealer_name,
				manheim_id: item.manheim_id ? item.manheim_id : null
			});
		}
		setSelectDealerships(list);
	}, [dealership.list, setSelectDealerships]);

	useEffect(() => {
		setResults({
			session_key: sessionKey
		});
		getSession(sessionKey);
		getDealerships({ limit: 50 }, false);
	}, [sessionKey, setResults, getSession, getDealerships]);

	useEffect(() => {
		setResults(r => {
			return {
				...r,
				response: { clientID: newManheimId, source: 'PaveCapture' }
			};
		});
	}, [newManheimId, setResults]);

	useEffect(() => {
		const licenseId =
			session.item && session.item.session_group
				? session.item.session_group.license_id
				: false;
		if (licenseId) {
			getLicense(licenseId);
		}
	}, [session.item, getLicense]);

	useMemo(() => {
		if (session.license && session.license.id) {
			const options = session.license.options || {};
			setSendEnable(
				options.muvit_url &&
					options.muvit_token &&
					options.muvit_secret &&
					options.muvit_integration
			);
		}
	}, [session.license, setSendEnable]);

	return (
		<section id="Session_view">
			<div className="heading">
				<h1>
					<span className="text-light">
						Resend Results To MUVIT API:{' '}
					</span>
					<span className="text-bold">{sessionKey}</span>
				</h1>
				<ul className="buttons">
					<li>
						<Link to="/sessions" className="btn btn-primary">
							<i className="la la-lg la-angle-left"></i>
							<span className="d-none d-sm-inline">Go Back</span>
						</Link>
					</li>
				</ul>
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-lg-4">
						<div className="card mb-3">
							<div className="card-header">
								<h2 className="card-title">
									Send Results For Session Key:{' '}
									<span className="text-success">
										{sessionKey}
									</span>
								</h2>
							</div>
							<div className="card-body">
								<div className="form-group" key="search-dealer">
									<label style={{ display: 'block' }}>
										Choose a dealership having manheim_id
									</label>
									<div
										className="input-group"
										style={{ position: 'relative' }}
									>
										<input
											type="text"
											name="dealer_name"
											className="form-control"
											placeholder="Search dealership by keywords..."
											autoComplete="off"
											value={
												filterSearchDealer &&
												filterSearchDealer.search
													? filterSearchDealer.search
													: ''
											}
											onChange={e =>
												onChangeFilterSearchDealer(e)
											}
										/>
										<i
											className="las la-lg la-angle-down"
											onClick={() =>
												setshowListSearchDealer(
													!showListSearchDealer
												)
											}
											style={{
												position: 'absolute',
												top: '10px',
												right: '5px'
											}}
										></i>
										{showListSearchDealer ? (
											<div
												className="list-search-dealer"
												style={{
													position: 'absolute',
													width: '100%',
													height: '175px',
													top: '40px',
													left: '0',
													background: '#fff',
													overflowY: 'scroll',
													borderRadius: '5px',
													zIndex: '99',
													border: '1px solid #e9ecef',
													padding: '0 10px'
												}}
											>
												{selectDealerships.length > 0 &&
													selectDealerships.map(
														item => {
															if (
																!item.manheim_id
															)
																return <></>;
															return (
																<div
																	onClick={() =>
																		onSetDealership(
																			item.manheim_id
																		)
																	}
																	key={`item-search=${item.key}`}
																	className="item"
																	style={{
																		cursor:
																			'pointer',
																		padding:
																			'5px 0'
																	}}
																	hover={{
																		background:
																			'red',
																		color:
																			'#fff'
																	}}
																>
																	{item.label}
																</div>
															);
														}
													)}
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-8">
						{newManheimId && (
							<div className="card mb-3">
								<div className="card-header">
									<h2 className="card-title">
										New Results Data
									</h2>
								</div>
								<div className="card-body">
									<div
										style={{
											maxHeight: '360px',
											overflowY: 'auto'
										}}
									>
										<pre>
											<code>
												{JSON.stringify(
													results,
													null,
													2
												)}
											</code>
										</pre>
									</div>
									<hr></hr>
									{sendEnable && (
										<button
											className="btn btn-success"
											onClick={onSendNewResult}
										>
											SEND NEW RESULTS
										</button>
									)}
									{apiResponse && apiResponse.status && (
										<div
											className={`mt-3 alert alert-${
												apiResponse.status === 'success'
													? 'success'
													: 'danger'
											}`}
										>
											<pre>
												<code>
													{JSON.stringify(
														apiResponse,
														null,
														2
													)}
												</code>
											</pre>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	session: state.session,
	dealership: state.dealership,
	auth: state.auth
});

const mapDispatchToProps = {
	getSession,
	getDealerships,
	getLicense
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionSendResults);
