import actions from './actions';

export const setLoading = () => {
	return dispatch => dispatch(actions.setLoading());
};

export const setResponse = e => {
	return dispatch => dispatch(actions.setResponse(e));
};

export const clearErrors = () => {
	return dispatch => dispatch(actions.clearErrors());
};

export const clearResponse = () => {
	return dispatch => dispatch(actions.clearResponse());
};

export const clearAll = () => {
	return dispatch => dispatch(actions.clearAll());
};
