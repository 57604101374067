import React from 'react';
import { Link } from 'react-router-dom';
import DateTime from '../../ui/components/DateTime';
import { uid } from '../../../utils/string';

export default ({ items, timezone }) => {
	return (
		<div className="table-responsive">
			<table
				id="Session_group_table"
				className="table table-vmid bg-white"
			>
				<thead>
					<tr>
						<th></th>
						<th>Dealership</th>
						<th>PIC</th>
						<th>Captured</th>
						<th># of Vehicles</th>
						<th>Created At</th>
						<th>Updated At</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(items) &&
						items.map((item) => {
							const index = uid();
							return (
								<tr key={index}>
									<td>
										<Link
											to={`/sessionGroup/${item.id}`}
											className={`btn btn-${
												item.theme === 'LITE'
													? 'warning'
													: 'primary'
											}`}
										>
											<small className="text-uc text-bold">
												PAVE
											</small>
										</Link>
									</td>
									<td>
										{item.dealership &&
											item.dealership.dealer_name}
									</td>
									<td>{item.rep && item.rep.username}</td>
									<td>{item.progress}</td>
									<td>{item.number_vehicles}</td>
									<td>
										<DateTime
											value={item.created_at}
											timezone={timezone}
											format="L LTS"
										/>
									</td>
									<td>
										<DateTime
											value={item.updated_at}
											timezone={timezone}
											format="L LTS"
										/>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
