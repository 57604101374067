import * as types from './types';

const getList = list => {
	return {
		type: types.GET_LIST,
		payload: list
	};
};

const setOne = data => {
	return {
		type: types.SET_ONE,
		payload: data
	};
};

const deleteOne = data => {
	return {
		type: types.DELETE_ONE,
		payload: data
	};
};

export default {
	getList,
	setOne,
	deleteOne
};
