import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	getSessionGroup,
	getDealership,
	launchSession,
	clearSessionGroup,
} from '../operations';
import { setLoading } from '../../ui/operations';
import SessionGroupDialog from '../components/SessionGroupDialog';
import Card from '../../ui/components/Card';

const captureUrl = process.env.REACT_APP_CAPTURE_BASE_URL;

export const SessionGroup = (props) => {
	const {
		dashboard,
		getSessionGroup,
		getDealership,
		launchSession,
		setLoading,
		clearSessionGroup,
	} = props;
	const { id } = props.match.params;
	const [group, setGroup] = useState(Object);
	const [dealership, setDealership] = useState(Object);
	const [launch, setLaunch] = useState(Object);
	const [errors, setErrors] = useState(Object);
	useEffect(() => {
		clearSessionGroup();
		getSessionGroup(id);
	}, [id, getSessionGroup, clearSessionGroup]);
	useEffect(() => {
		if (group.dealership_id && !dealership.id) {
			getDealership(group.dealership_id, null, false);
		}
	}, [group, dealership, getDealership]);

	useEffect(() => {
		setGroup(dashboard.group);
	}, [dashboard, setGroup]);

	useEffect(() => {
		setDealership(dashboard.dealership);
	}, [dashboard, setDealership]);

	const onLaunchSession = async (e, item) => {
		e.preventDefault();
		const valid =
			item.theme !== 'LITE' || (item.theme === 'LITE' && launch.type);
		if (item.session_key && valid) {
			const success = await launchSession(item.session_key, {
				...launch,
			});
			if (success) {
				setLoading();
				window.location.replace(
					`${captureUrl}/home/${item.session_key}/stage=0`
				);
			}
		} else {
			setErrors({ ...errors, type: 'Please select vehicle type.' });
		}
	};

	if (!captureUrl) {
		return (
			<SessionGroupDialog
				heading="Capture URL is not setup properly"
				content={
					<p>Please contact administrator for more information.</p>
				}
			/>
		);
	}

	if (!group.id) {
		return <SessionGroupDialog heading="Session Group not found" />;
	}

	if (
		group.progress &&
		group.number_vehicles &&
		group.progress === group.number_vehicles
	) {
		return (
			<SessionGroupDialog
				heading="Capture Finished"
				content={
					<p>Well done, you have finished this capture sessions.</p>
				}
			/>
		);
	}

	return (
		<section id="Session_group">
			<div className="heading">
				<h1 className="text-left">Capture Information</h1>
			</div>
			<div className="container-fluid">
				<Link to="/dashboard" className="btn btn-secondary mb-2">
					<i className="las la-chevron-left mr-1"></i>
					<span className="font-weight-bold text-uppercase">
						Back to dashboard
					</span>
				</Link>
				<div className="row">
					{group && group.sessions
						? group.sessions.map((item, index) => {
								return (
									<div
										className="col-12 col-md-6"
										key={`pave-vehice-` + index}
									>
										<Card
											title="Vehicle Capture"
											body={
												<>
													{item.theme === 'LITE' && (
														<p>
															For each vehicle
															capture, please
															select if the
															vehicle is new,
															used.
														</p>
													)}
													<div className="form-group">
														<label>
															Dealership
														</label>
														<div>
															{dealership &&
																dealership.dealer_name}
														</div>
													</div>
													<div className="form-group">
														<label>
															Session ID
														</label>
														<div>
															<b className="lead">
																{
																	item.session_key
																}
															</b>
														</div>
													</div>
													{group.theme === 'LITE' && (
														<div className="form-group">
															<label>
																Vehicle Type
															</label>
															<div>
																<div className="btn-group">
																	<button
																		className={`btn btn-${
																			launch.type ===
																			'NEW'
																				? 'primary'
																				: 'light'
																		}`}
																		onClick={() =>
																			setLaunch(
																				(
																					c
																				) => {
																					return {
																						...c,
																						type:
																							'NEW',
																					};
																				}
																			)
																		}
																	>
																		NEW
																	</button>
																	<button
																		className={`btn btn-${
																			launch.type ===
																			'USED'
																				? 'primary'
																				: 'light'
																		}`}
																		onClick={() =>
																			setLaunch(
																				(
																					c
																				) => {
																					return {
																						...c,
																						type:
																							'USED',
																					};
																				}
																			)
																		}
																	>
																		USED
																	</button>
																</div>
																{errors.type && (
																	<div className="small text-danger mt-2">
																		{
																			errors.type
																		}
																	</div>
																)}
															</div>
														</div>
													)}
													<div className="row">
														<div className="col-12 col-md-12">
															{item.status ===
															'COMPLETE' ? (
																<div className="alert alert-success text-center mt-3">
																	<span className="font-weight-bold text-uppercase text-dark ">
																		Completed
																	</span>
																</div>
															) : (
																<button
																	className="btn btn-block btn-warning mt-3"
																	onClick={(
																		e
																	) =>
																		onLaunchSession(
																			e,
																			item
																		)
																	}
																>
																	<span className="font-weight-bold">
																		{item.status ===
																		'IDLE'
																			? 'PAVE Vehicle'
																			: 'Continue'}
																	</span>
																</button>
															)}
														</div>
													</div>
												</>
											}
										/>
									</div>
								);
						  })
						: null}
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
	auth: state.auth,
});

const mapDispatchToProps = {
	getSessionGroup,
	getDealership,
	launchSession,
	setLoading,
	clearSessionGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionGroup);
