import Alert from './Alert';
import Loading from './Loading';
import NotFound from './NotFound';
import Forbidden from './Forbidden';
export default {
	Alert,
	Loading,
	NotFound,
	Forbidden
};
