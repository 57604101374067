import actions from './actions';
import * as client from '../../utils/client';

export const getRequests = (data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/requests',
			data,
			callback: function(res) {
				dispatch(actions.getList(res));
			},
			loading
		});
};

export const createRequest = (data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/request/create',
			data,
			callback: function(res) {
				dispatch(actions.setOne(res));
			},
			loading
		});
};

export const getRequest = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/request/' + id,
			data,
			callback: function(res) {
				dispatch(actions.setOne(res));
			},
			loading
		});
};

export const editRequest = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'put',
			url: '/request/' + id,
			data,
			callback: function(res) {
				dispatch(actions.setOne(res));
			},
			loading
		});
};

export const completeRequest = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/request/complete/' + id,
			data,
			callback: function(res) {
				dispatch(actions.setOne(res));
			},
			loading
		});
};

export const deleteRequest = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'delete',
			url: '/request/' + id,
			data,
			callback: function(res) {
				dispatch(actions.deleteOne(res));
			},
			loading
		});
};
