import actions from './actions';
import * as client from '../../utils/client';

export const getList = (type = 'users', data, loading = true) => {
	const isolationSites = process.env.REACT_APP_ISOLATION_MODE || false;
	console.log(isolationSites);
	const isolationMode =
		isolationSites &&
		window.location.hostname &&
		isolationSites.includes(window.location.hostname);
	console.log(isolationMode);
	const url = isolationMode ? '/created_reps' : '/' + type;
	return dispatch =>
		client.exec({
			url,
			data,
			callback: function(res) {
				dispatch(actions.getList(res));
			},
			loading
		});
};

export const createOne = (data, loading = true) => {
	const { role } = data;
	if (!role || !['REP_MANAGER', 'REP'].includes(role)) {
		return Promise.resolve(false);
	}
	return dispatch =>
		client.exec({
			type: 'post',
			url: `/${role.toLowerCase()}/create`,
			data,
			callback: function(res) {
				dispatch(actions.createOne(res));
			},
			loading
		});
};

export const getOne = (id, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/user/' + id,
			callback: function(res) {
				dispatch(actions.getOne(res));
			},
			loading
		});
};

export const editOne = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'put',
			url: '/user/' + id,
			data,
			callback: function(res) {
				dispatch(actions.editOne(res));
			},
			loading
		});
};

export const editOneRole = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'put',
			url: '/rep/role/' + id,
			data,
			callback: function(res) {
				dispatch(actions.editOneRole(res));
			},
			loading
		});
};

export const toggleActive = (id, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'put',
			url: '/rep/toggle_active/' + id,
			callback: function(res) {
				dispatch(actions.editOne(res));
			},
			loading
		});
};

export const activeDealership = (id, type, data, loading = true) => {
	if (!['assign', 'retake'].includes(type)) {
		return Promise.resolve(false);
	}
	return dispatch =>
		client.exec({
			type: 'post',
			url: `/dealership/${type}/${id}`,
			data,
			callback: function(res) {
				dispatch(actions.activeDealership(res));
			},
			loading
		});
};

export const uploadReps = (data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/rep/csv',
			data,
			callback: function(res) {
				dispatch(actions.uploadReps(res));
			},
			loading
		});
};

export const downloadReps = () => {
	return () =>
		client.download(
			'/rep/csv',
			{},
			function(res) {
			const url = window.URL.createObjectURL(new Blob([res]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `PAVE_reps_${Date.now()}.csv`);
			document.body.appendChild(link);
			link.click();
		});
};
