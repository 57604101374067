import React, { useState } from 'react';
import { connect } from 'react-redux';
import { completeRequest } from '../../requests/operations';
import { uid } from '../../../utils/string';
import $ from 'jquery';
import DateTime from '../../ui/components/DateTime';

const RequestTableRep = (props) => {
	const {
		completeRequest,
		editable = true,
		items,
		refresh,
		refreshParams,
		onStart,
	} = props;
	const [editValues, setEditValues] = useState(Object);

	const onComplete = (id) => {
		setEditValues({ id });
	};

	const onCompleteConfirm = async (e) => {
		e.preventDefault();
		if (editValues.id) {
			const success = await completeRequest(editValues.id);
			if (success) {
				refresh({ ...refreshParams });
				$('#Modal_complete_close').click();
			}
		}
	};

	const list =
		Array.isArray(items) &&
		items.map((item) => {
			const index = uid();
			const dealership = item.dealership || {};
			const rep = item.rep || {};
			let status = {};
			if (item.status === 'COMPLETED') {
				status.class = 'success';
				status.label = 'Complete';
			} else if (item.status === 'IN-QUEUE') {
				status.class = 'secondary';
				status.label = 'Not Started';
			} else {
				status.class = 'info';
				status.label = 'In Progress';
			}
			return (
				<tr key={index}>
					{editable && (
						<td>
							{item.status === 'IN-QUEUE' && (
								<button
									className="btn btn-outline-warning"
									onClick={() => {
										onStart(item);
									}}
								>
									<small className="text-uppercase text-bold">
										Start
									</small>
								</button>
							)}
							{item.status === 'PROCESSING' && (
								<button
									className="btn btn-outline-success"
									data-toggle="modal"
									data-target="#Modal_complete"
									onClick={() => {
										onComplete(item.id);
									}}
								>
									<small className="text-uppercase text-bold">
										Complete
									</small>
								</button>
							)}
						</td>
					)}
					<td>
						<span
							className={`small text-uppercase text-bold text-${status.class}`}
						>
							{status.label}
						</span>
					</td>
					<td>{dealership.dealer_name || '--'}</td>
					<td>{dealership.address || '--'}</td>
					<td>{rep.username || '--'}</td>
					<td>
						<DateTime
							value={item.todo_date}
							format="ddd, MMM DD, YYYY"
						/>
					</td>
					<td>{item.time_of_date}</td>
					<td>{item.number_vehicles}</td>
					<td>{item.contact_name}</td>
					<td>{item.contact_number}</td>
				</tr>
			);
		});

	return (
		<>
			<div className="table-responsive">
				<table id="Request_table" className="table table-vmid">
					<thead>
						<tr>
							{editable && <th></th>}
							<th>Status</th>
							<th>Dealership</th>
							<th>Address</th>
							<th>Rep</th>
							<th>Date</th>
							<th>Time</th>
							<th># of Vehicles</th>
							<th>Contact Name</th>
							<th>Contact Number</th>
						</tr>
					</thead>
					<tbody>{list}</tbody>
				</table>
			</div>
			<div
				id="Modal_complete"
				className="modal fade"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<p>Please confirm to complete this request.</p>
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-success"
								onClick={onCompleteConfirm}
							>
								<span className="text-uppercase text-bold">
									Yes, I Confirm
								</span>
							</button>
							<button
								id="Modal_complete_close"
								className="btn btn-outline-secondary"
								data-dismiss="modal"
							>
								<span className="text-uppercase text-bold">
									Close
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	request: state.request,
});

const mapDispatchToProps = {
	completeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTableRep);
