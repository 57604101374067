import * as types from './types';
import { isOneEmpty } from '../../utils/validate';

const initialState = {
	isAuthenticated: false,
	user: {},
	account: {},
	response: {},
	errors: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isOneEmpty(action.payload),
				user: action.payload
			};
		case types.GET_LOGGED_USER:
			return {
				...state,
				account: action.payload
			};
		case types.CLEAR_CURRENT_USER:
			return {
				...state,
				isAuthenticated: false,
				user: {},
				account: {}
			};
		case types.CLEAR_RESPONSE:
			return {
				...state,
				response: {}
			};
		default:
			return state;
	}
}
