import actions from './actions';
import * as client from '../../utils/client';

export const getRequests = (data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/requests',
			data,
			callback: function(res) {
				dispatch(actions.getRequests(res));
			},
			loading
		});
};

export const getDealerships = (data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/dealerships',
			data,
			callback: function(res) {
				dispatch(actions.getDealerships(res));
			},
			loading
		});
};

export const getSessionGroups = (data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/session/groups',
			data,
			callback: function(res) {
				dispatch(actions.getSessionGroups(res));
			},
			loading
		});
};

export const getSessionGroup = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/session/group/' + id,
			data,
			callback: function(res) {
				dispatch(actions.getSessionGroup(res));
			},
			loading
		});
};

export const getDealership = (id, data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/dealership/' + id,
			data,
			callback: function(res) {
				dispatch(actions.getDealership(res));
			},
			loading
		});
};

export const createSessionGroup = (data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/session/group/create',
			data,
			callback: function(res) {
				dispatch(actions.createSessionGroup(res));
			},
			loading
		});
};

export const launchSession = (sessionKey, data, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/session/launch/' + sessionKey,
			data,
			callback: function(res) {
				dispatch(actions.launchSession(res));
			},
			loading
		});
};

export const clearSessionGroup = () => {
	return dispatch => dispatch(actions.clearSessionGroup());
};
