import React from 'react';
import DateTime from '../../ui/components/DateTime';

const SessionViewInfo = (props) => {
	const { item, auth } = props;
	const { session_group } = item;
	let status = {};
	if (item.status === 'COMPLETE') {
		status.class = 'success';
		status.label = 'Complete';
	} else if (item.status === 'IDLE') {
		status.class = 'secondary';
		status.label = 'Not Yet Started';
	} else {
		status.class = 'info';
		status.label = 'In Progress';
	}
	return (
		<div id="Session_info">
			<div className="row">
				<div className="col-6">
					<div className="form-group">
						<label>SessionID / InspectionID</label>
						<div className="text-bold">{item.session_key}</div>
						<div className="text-secondary small">
							/ {item.inspection_id}
						</div>
					</div>
					<div className="form-group">
						<label>Status</label>
						<div
							className={`text-uppercase text-bold text-${status.class}`}
						>
							{status.label}
						</div>
					</div>
					<div className="form-group">
						<label>Dealership Name</label>
						<div>
							{session_group &&
								session_group.dealership &&
								session_group.dealership.dealer_name}
						</div>
					</div>
					<div className="form-group">
						<label>Rep User</label>
						<div>
							{session_group &&
							session_group.rep &&
							session_group.rep.username
								? `${session_group.rep.firstname} ${session_group.rep.lastname}`
								: ''}
						</div>
					</div>
					{item.session_key && (
						<>
							<div className="form-group">
								<a
									href={`${process.env.REACT_APP_CAPTURE_BASE_URL}/${item.session_key}/result/reportdamages`}
									className="btn btn-primary w-100 disabled"
									target="_blank"
									rel="noopener noreferrer"
								>
									Edit Report
								</a>
							</div>
							<div className="form-group">
								<a
									href={`${process.env.REACT_APP_REPORT_BASE_URL}/report/${item.session_key} `}
									className="btn btn-primary w-100"
									target="_blank"
									rel="noopener noreferrer"
								>
									Condition Report PDF
								</a>
							</div>
						</>
					)}
				</div>
				<div className="col-6">
					<div className="form-group">
						<label>Created At</label>
						<div className="text-bold text-secondary">
							<DateTime
								value={item.created_at}
								timezone={auth.account && auth.account.timezone}
							/>
						</div>
					</div>
					<div className="form-group">
						<label>Started At</label>
						<div className="text-bold text-dark">
							<DateTime
								value={item.inspect_started_at}
								timezone={auth.account && auth.account.timezone}
							/>
						</div>
					</div>
					<div className="form-group">
						<label>Finished At</label>
						<div className="text-bold text-dark">
							<DateTime
								value={item.inspect_ended_at}
								timezone={auth.account && auth.account.timezone}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SessionViewInfo;
