import * as types from './types';

const getRequests = list => {
	return {
		type: types.GET_REQUESTS,
		payload: list
	};
};

const getDealerships = list => {
	return {
		type: types.GET_DEALERSHIPS,
		payload: list
	};
};

const getDealership = item => {
	return {
		type: types.GET_DEALERSHIP,
		payload: item
	};
};

const getSessionGroups = list => {
	return {
		type: types.GET_SESSION_GROUPS,
		payload: list
	};
};

const getSessionGroup = item => {
	return {
		type: types.GET_SESSION_GROUP,
		payload: item
	};
};

const getCompleteSessions = list => {
	return {
		type: types.GET_COMPLETE_SESSIONS,
		payload: list
	};
};

const createSessionGroup = item => {
	return {
		type: types.CREATE_SESSION_GROUP,
		payload: item
	};
};

const launchSession = item => {
	return {
		type: types.LAUNCH_SESSION,
		payload: item
	};
};

const clearSessionGroup = () => {
	return {
		type: types.CLEAR_SESSION_GROUP
	};
};

export default {
	getRequests,
	getDealerships,
	getDealership,
	getSessionGroups,
	getSessionGroup,
	getCompleteSessions,
	createSessionGroup,
	launchSession,
	clearSessionGroup
};
