export default [
	{
		key: 'manheim_id',
		label: 'Manheim ID',
		required: true
	},
	{
		key: 'ddc_id',
		label: 'DDC ID'
	},
	{
		key: 'dealertrack_id',
		label: 'Dealertrack ID'
	},
	{
		key: 'dealer_name',
		label: 'Dealership Name',
		required: true
	},
	{
		key: 'email',
		label: 'Dealership Email',
		required: true
	},
	{
		key: 'contact_name',
		label: 'Contact Name'
	},
	{
		key: 'contact_tel',
		label: 'Phone Number',
		isPhone: true
	},
	{
		key: 'address',
		label: 'Dealership Address',
		required: true
	},
	{
		key: 'city',
		label: 'Dealership City',
		required: true
	},
	{
		key: 'province',
		label: 'Dealership Province',
		required: true
	},
	{
		key: 'postal_code',
		label: 'Postal Code'
	}
];
