import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { getSessions, MonthlySessionCSV, sendMuvit } from '../operations';
import { getList as getDealerships } from '../../dealerships/operations';
import { getList as getReps } from '../../reps/operations';
import { getRequests, createRequest } from '../../requests/operations';
import { setResponse } from '../../ui/operations';
import RequestTable from '../../requests/components/RequestTable';
import RequestTableUser from '../../requests/components/RequestTableUser';
import SessionTable from '../components/SessionTable';
import DisplayHeadButton from '../../ui/components/DisplayHeadButton';
import Paginator from '../../ui/components/Paginator';
import Form from '../../ui/components/Form';
import Card from '../../ui/components/Card';
import requestFields from '../../requests/settings/RequestFields';
import SearchInput from '../components/SearchInput';

import { hasErrors } from '../../../utils/validate';
import _pick from 'lodash/pick';
import '../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const defaultParams = { limit: 5, sort: 'created_at' };
const defaultRequestParams = {
	sort: 'created_at',
	sortBy: 'desc'
};
const defaultPageFields = ['page', 'pages', 'count', 'limit'];
const defaultRefreshTimer = 100000;
const defaultList = {
	time_of_date: [
		{ key: 'Morning', label: 'Morning' },
		{
			key: 'Afternoon',
			label: 'Afternoon'
		},
		{ key: 'All day', label: 'All day' }
	]
};

const fetch = (action, type, page, search, inspect_started_at, city, province) => {
	if (page) {
		action(type, {
			...defaultParams,
			sortBy: type === 'complete' ? 'desc' : 'asc',

			page,
			search,
			inspect_started_at,
			city,
			province,
		});
	}
};

export const SessionList = props => {
	const {
		auth,
		dealership,
		session,
		request,
		getSessions,
		MonthlySessionCSV,
		sendMuvit,
		getDealerships,
		getRequests,
		createRequest,
		setResponse,
		getReps,
		rep
	} = props;

	const d = new Date();
	const CSVFileName =
		'monthly_inspections_pave_manager_' + (d.getMonth() + 1) + '_' + d.getFullYear() + '.csv';
	const { account } = auth;
	const [completeSessions, setCompleteSessions] = useState(Array);
	const [completePagi, setCompletePagi] = useState(Object);
	const [incompleteSessions, setIncompleteSessions] = useState(Array);
	const [incompletePagi, setIncompletePagi] = useState(Object);
	const [requests, setRequests] = useState(Array);
	const [requestPagi, setRequestPagi] = useState(Object);
	const [requestFilters, setRequestFilters] = useState({
		status: 'INCOMPLETE'
	});
	const [filterSearchDealer, setfilterSearchDealer] = useState(Object);
	const [filterSearchRep, setfilterSearchRep] = useState(Object);
	const [showListSearchDealer, setshowListSearchDealer] = useState(false);
	const [showListSearchRep, setshowListSearchRep] = useState(false);
	const [displayBox, setDisplayBox] = useState(String);
	const [requestInfo, setRequestInfo] = useState({
		number_vehicles: 1,
		time_of_date: 'Morning'
	});
	const [fieldList, setFieldList] = useState({ ...defaultList });
	const [filterIncomplete, setfilterIncomplete] = useState(Object);
	const [filterComplete, setfilterComplete] = useState(Object);
	const [filterStartDateComplete, setFilterStartDateComplete] = useState(String);
	const [filterStartDateIncomplete, setFilterStartDateIncomplete] = useState(String);
	const [filterDownload, setFilterDownload] = useState(Object);
	const [filterCPComplete, setFilterCPComplete] = useState(Object);
	const [errors, setErrors] = useState(Object);
	const fetchComplete = useRef(null);
	const fetchIncomplete = useRef(null);

	const runFetchComplete = useCallback(() => {
		const page = completePagi.page || 1;
		const valueSearch = filterComplete.search || '';
		if (fetchComplete.current !== null) {
			return;
		}
		if (!completePagi.page) {
			fetch(getSessions, 'complete', page);
		}
		fetchComplete.current = setInterval(() => {
			fetch(getSessions, 'complete', page, valueSearch);
		}, defaultRefreshTimer);
	}, [completePagi.page, getSessions, filterComplete.search]);

	const stopFetchComplete = useCallback(() => {
		if (fetchComplete.current === null) {
			return;
		}
		clearInterval(fetchComplete.current);
		fetchComplete.current = null;
	}, []);

	useEffect(() => {
		runFetchComplete();
		return () => {
			stopFetchComplete();
		};
	}, [runFetchComplete, stopFetchComplete]);

	const runFetchIncomplete = useCallback(() => {
		const page = incompletePagi.page || 1;
		if (fetchIncomplete.current !== null) {
			return;
		}
		const valueSearch = filterIncomplete.search || '';
		if (!incompletePagi.page) {
			fetch(getSessions, 'incomplete', page);
		}
		fetchIncomplete.current = setInterval(() => {
			fetch(getSessions, 'incomplete', page, valueSearch);
		}, defaultRefreshTimer);
	}, [incompletePagi.page, getSessions, filterIncomplete.search]);

	const stopFetchIncomplete = useCallback(() => {
		if (fetchIncomplete.current === null) {
			return;
		}
		clearInterval(fetchIncomplete.current);
		fetchIncomplete.current = null;
	}, []);

	useEffect(() => {
		runFetchIncomplete();
		return () => {
			stopFetchIncomplete();
		};
	}, [runFetchIncomplete, stopFetchIncomplete]);

	useEffect(() => {
		if (['USER', 'REP_MANAGER'].includes(account.role)) {
			getRequests(
				{
					...defaultParams,
					...defaultRequestParams,
					...requestFilters
				},
				false
			);
		}
	}, [account.role, requestFilters, getRequests]);

	useEffect(() => {
		setCompleteSessions(session.complete.data || []);
		const pageInfo = _pick(session.complete, defaultPageFields);
		if (pageInfo.page) {
			setCompletePagi(pageInfo);
		}
	}, [session.complete, setCompleteSessions]);

	useEffect(() => {
		setIncompleteSessions(session.incomplete.data || []);
		const pageInfo = _pick(session.incomplete, defaultPageFields);
		if (pageInfo.page) {
			setIncompletePagi(pageInfo);
		}
	}, [session.incomplete, setIncompleteSessions]);

	useEffect(() => {
		setRequests(request.list.data || []);
		setRequestPagi(_pick(request.list, defaultPageFields));
	}, [request.list]);

	useEffect(() => {
		if (
			dealership.list.data &&
			dealership.list.data.length > 0 &&
			(!fieldList.dealership_id ||
				fieldList.dealership_id.length !== dealership.list.data.length)
		) {
			let dealership_id = [];
			for (let i = 0; i < dealership.list.data.length; i++) {
				dealership_id.push({
					key: dealership.list.data[i].id,
					label: dealership.list.data[i].dealer_name
				});
			}
			setFieldList(prevState => {
				return { ...prevState, dealership_id };
			});
		}
		if (rep.list.data && rep.list.data.length > 0 && !fieldList.rep_id) {
			let rep_id = [];
			for (let i = 0; i < rep.list.data.length; i++) {
				rep_id.push({
					key: rep.list.data[i].id,
					label: `${rep.list.data[i].firstname} ${rep.list.data[i].lastname}`
				});
			}
			setFieldList(prevState => {
				return { ...prevState, rep_id };
			});
		}
	}, [dealership.list, fieldList.dealership_id, rep.list, fieldList.rep_id]);

	useEffect(() => {
		fetch(getSessions, 'complete', 1, filterComplete.search, filterStartDateComplete, filterCPComplete.city, filterCPComplete.province);
	}, [filterCPComplete, filterComplete, filterStartDateComplete, getSessions]);

	const onDisplayBox = box => {
		if (displayBox !== box) {
			setDisplayBox(box);
			if (box === 'create-request') {
				getDealerships({ limit: 25 }, false);
				getReps('reps', { limit: 50 }, false);
			}
		} else {
			setDisplayBox('');
		}
	};

	const onChangeRequest = (e, nameSearch, valueSearch, label) => {
		let name = '';
		let value = '';
		if (name || valueSearch || label) {
			name = nameSearch;
			value = valueSearch;
			switch (name) {
				case 'dealership_id':
					setfilterSearchDealer({
						searchKey: nameSearch,
						search: label
					});
					setshowListSearchDealer(false);
					break;
				case 'rep_id':
					setfilterSearchRep({
						searchKey: nameSearch,
						search: label
					});
					setshowListSearchRep(false);
					break;
				default:
					break;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}
		setRequestInfo({ ...requestInfo, [name]: value });
	};
	const onChangeFilterSearchDealer = e => {
		e.preventDefault();
		setfilterSearchDealer({
			searchKey: e.target.name,
			search: e.target.value
		});
		setshowListSearchDealer(true);
		getDealerships({
			search: e.target.value,
			searchKey: 'dealer_name',
			limit: 25
		});
	};
	const onChangeFilterSearchRep = e => {
		e.preventDefault();
		setfilterSearchRep({
			searchKey: e.target.name,
			search: e.target.value
		});
		setshowListSearchRep(true);
		getReps('reps', {
			search: e.target.value,
			limit: 25
		});
	};
	const onCreateRequest = async e => {
		e.preventDefault();
		const validate = hasErrors(requestFields, requestInfo);
		if (validate.status) {
			setErrors(validate.list);
		} else {
			setErrors({});
			const success = await createRequest(requestInfo);
			if (success) {
				setResponse({
					response: {
						status: 200,
						message: `A request has been made.`
					}
				});
				setDisplayBox('');
				setRequestInfo({});
				setfilterSearchRep({});
				setfilterSearchDealer({});
				getRequests(
					{
						...defaultParams,
						...defaultRequestParams,
						...requestFilters
					},
					false
				);
			}
		}
	};

	const onSelectCityDownload = (e) => {
		setFilterDownload({
			...filterDownload,
			...{city: e}
		});
	};

	const onSelectProvinceDownload = (e) => {
		setFilterDownload({
			...filterDownload,
			...{province: e}
		});
	};

	const onChangeRequestPagi = ({ page, limit }) => {
		setRequestPagi({ ...requestPagi, page, limit });
		getRequests(
			{
				...defaultParams,
				...defaultRequestParams,
				...requestFilters,
				page,
				limit
			},
			false
		);
	};

	const onChangeFilter = e => {
		const { value, name } = e.target;
		switch (name) {
			case 'complete':
				setfilterComplete({ search: value });
				// fetch(getSessions, 'complete', 1, value, filterStartDateComplete, filterCPComplete.city, filterCPComplete.province);
				break;
			case 'incomplete':
				setfilterIncomplete({ search: value });
				fetch(getSessions, 'incomplete', 1, value, filterStartDateIncomplete);
				break;
			case 'inspect_started_at_complete':
				setFilterStartDateComplete(value);
				// fetch(getSessions, 'complete', 1, filterComplete.search, value, filterCPComplete.city, filterCPComplete.province);
				break;
			case 'inspect_started_at_incomplete':
				setFilterStartDateIncomplete(value);
				fetch(getSessions, 'incomplete', 1, setfilterIncomplete.search, value);
				break;
			default:
				break

		}
	};

	const onChangeFilterCity = (e) => {
		setFilterCPComplete({
			...filterCPComplete,
			...{city: e}
		})
	};

	const onChangeFilterProvince = (e) => {
		setFilterCPComplete({
			...filterCPComplete,
			...{province: e}
		})
	};

	const onChangeCompletePagi = ({ page }) => {
		setCompletePagi({ ...completePagi, page });
		const valueSearch = filterComplete.search || '';
		fetch(getSessions, 'complete', page, valueSearch, filterStartDateComplete, filterCPComplete.city, filterCPComplete.province);
	};

	const onChangeIncompletePagi = ({ page }) => {
		setIncompletePagi({ ...incompletePagi, page });
		const valueSearch = filterIncomplete.search || '';
		fetch(getSessions, 'incomplete', page, valueSearch);
	};
	const startDay = moment().startOf('day').format("YYYY-MM-DD HH:mm:ss");
	const endDay = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");

	const startWeek = moment().startOf('week').format("YYYY-MM-DD HH:mm:ss");
	const endWeek = moment().endOf('week').format("YYYY-MM-DD HH:mm:ss");

	const startMonth = moment().startOf('month').format("YYYY-MM-DD HH:mm:ss");
	const endMonth = moment().endOf('month').format("YYYY-MM-DD HH:mm:ss");

	const filterDateOptions = [
		{
			label: "This day",
			value: startDay + "/" + endDay,
		},
		{
			label: "This week",
			value: startWeek + "/" + endWeek,
		},
		{
			label: "This month",
			value: startMonth + "/" + endMonth,
		}
	];

	return (
		<section id="Session_list">
			<div className="heading">
				<h1>Session Manager</h1>
				{account.role === 'REP_MANAGER' && (
					<ul className="buttons">
						<li>
							<DisplayHeadButton
								buttonClass="warning"
								buttonLabel="Create Request"
								action={() => {
									onDisplayBox('create-request');
								}}
								hideOnMobile={false}
							/>
						</li>
					</ul>
				)}
			</div>
			<div className="container-fluid">
				<div className="row">
					<div
						className={`${displayBox ? 'col-12 col-lg-3' : 'hide'}`}
					>
						<div
							className={`card mb-3 ${
								displayBox === 'create-request'
									? 'show'
									: 'hide'
							}`}
						>
							<div className="card-header bg-warning">
								<h2 className="card-title">
									<span>New request information</span>
									<i
										className="clickable la la-lg la-times float-right"
										onClick={() => setDisplayBox('')}
									></i>
								</h2>
							</div>
							<div className="card-body">
								<Form
									fields={requestFields}
									values={requestInfo}
									list={fieldList}
									errors={errors}
									onChangeInput={onChangeRequest}
									timezone={
										auth &&
										auth.account &&
										auth.account.timezone
									}
									filterSearchDealer={filterSearchDealer}
									filterSearchRep={filterSearchRep}
									onChangeFilterSearchDealer={e =>
										onChangeFilterSearchDealer(e)
									}
									onChangeFilterSearchRep={e =>
										onChangeFilterSearchRep(e)
									}
									onOffListDealer={() =>
										setshowListSearchDealer(
											!showListSearchDealer
										)
									}
									onOffListRep={() =>
										setshowListSearchRep(!showListSearchRep)
									}
									showListSearchRep={showListSearchRep}
									showListSearchDealer={showListSearchDealer}
								></Form>
								<button
									className="btn btn-block btn-warning"
									onClick={onCreateRequest}
								>
									<span>Submit</span>
								</button>
							</div>
						</div>
					</div>
					<div
						className={`${
							displayBox ? 'col-12 col-lg-9' : 'col-12'
						}`}
					>
						{['USER', 'REP_MANAGER'].includes(account.role) && (
							<Card
								title="Created Requests"
								body={
									<>
										<Paginator
											{...requestPagi}
											action={onChangeRequestPagi}
											fixedLimit={5}
										/>
										<div className="btn-group mb-3">
											<button
												className={`btn ${
													requestFilters.status ===
													'INCOMPLETE'
														? 'btn-primary'
														: 'btn-light'
												}`}
												onClick={() => {
													setRequestFilters({
														status: 'INCOMPLETE'
													});
												}}
											>
												<small>In Progress</small>
											</button>
											<button
												className={`btn ${
													requestFilters.status ===
													'COMPLETED'
														? 'btn-primary'
														: 'btn-light'
												}`}
												onClick={() => {
													setRequestFilters({
														status: 'COMPLETED'
													});
												}}
											>
												<small>COMPLETED</small>
											</button>
										</div>
										{account.role === 'REP_MANAGER' && (
											<RequestTable
												items={requests}
												refresh={getRequests}
												refreshParams={{
													...defaultParams,
													...defaultRequestParams
												}}
												timezone={
													auth &&
													auth.account &&
													auth.account.timezone
												}
											/>
										)}
										{account.role === 'USER' && (
											<RequestTableUser
												items={requests}
												timezone={
													auth &&
													auth.account &&
													auth.account.timezone
												}
											/>
										)}
									</>
								}
							/>
						)}
						<Card
							title="Completed Sessions"
							titleButtons={
								<div className="mt-3 content-download-complete-session">
									<div className="input-group">
										<button
											className="btn btn-sm btn-warning form-control"
											onClick={() =>
												MonthlySessionCSV(CSVFileName, filterDownload)
											}
										>
											<i className="la la-lg la-file-csv"/>
											<span className="small text-bold">Download Sessions</span>
										</button>
										<SearchInput name="city" selectItem={onSelectCityDownload}/>
										<SearchInput name="province" selectItem={onSelectProvinceDownload}/>
									</div>
								</div>
							}
							body={
								<>
									<Paginator
										{...completePagi}
										action={onChangeCompletePagi}
										fixedLimit={5}
									/>
									<div className="row">
										<div className="col">
											<input
												type="text"
												name="complete"
												className="form-control inline mb-2"
												placeholder="Search Session Key"
												value={filterComplete.search || ''}
												onChange={onChangeFilter}
											/>
										</div>
										<div className="col">
											<select
												name="inspect_started_at_complete"
												className="form-control inline mb-2"
												onChange={onChangeFilter}
												defaultValue={''}
											>
												<option value="" >Filter date</option>
												{filterDateOptions.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
											</select>
										</div>
										<div className="col">
											<SearchInput name="city" selectItem={onChangeFilterCity} layout="normal"/>
										</div>
										<div className="col">
											<SearchInput name="province" selectItem={onChangeFilterProvince} layout="normal"/>
										</div>
									</div>
									<SessionTable
										items={completeSessions}
										sendMuvit={sendMuvit}
										timezone={
											auth &&
											auth.account &&
											auth.account.timezone
										}
									/>
								</>
							}
						/>
						<Card
							title="Incomplete Sessions"
							body={
								<>
									<Paginator
										{...incompletePagi}
										action={onChangeIncompletePagi}
										fixedLimit={5}
									/>
									<div className="row">
										<div className="col">
											<input
												type="text"
												name="incomplete"
												className="form-control inline mb-2"
												placeholder="Search Session Key"
												value={
													filterIncomplete.search || ''
												}
												onChange={onChangeFilter}
											/>
										</div>
										<div className="col">
											<select
												name="inspect_started_at_incomplete"
												className="form-control inline mb-2"
												onChange={onChangeFilter}
												defaultValue={''}
											>
												<option value="" >Filter date</option>
												{filterDateOptions.map(({ value, label }, index) => <option key={index} value={value} >{label}</option>)}
											</select>
										</div>
									</div>
									<SessionTable
										items={incompleteSessions}
										timezone={
											auth &&
											auth.account &&
											auth.account.timezone
										}
									/>
								</>
							}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	session: state.session,
	dealership: state.dealership,
	auth: state.auth,
	request: state.request,
	rep: state.rep
});

const mapDispatchToProps = {
	getSessions,
	MonthlySessionCSV,
	sendMuvit,
	getDealerships,
	getRequests,
	createRequest,
	setResponse,
	getReps
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionList);
