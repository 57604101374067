import React, { useState, useEffect } from 'react';

export const InputNumber = (props) => {
	const { onChangeTotal, label, value } = props;
	const name = props.name || 'total';
	const min = props.min || 1;
	const max = props.max || 50;
	const step = props.step || 1;
	const [number, setNumber] = useState(Number);

	useEffect(() => {
		setNumber(value);
	}, [value]);

	const onChangeNumber = (e) => {
		const value = validNumber(parseInt(e.target.value));
		setFinalNumber(value);
	};

	const setFinalNumber = (num) => {
		setNumber(num);
		onChangeTotal({ target: { name, value: num } });
	};

	const validNumber = (value) => {
		if (value < min) {
			return min;
		} else if (value > max) {
			return max;
		}
		return isNaN(value) ? min : value;
	};

	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="input-group">
				<div className="input-group-prepend">
					<button
						className="btn btn-secondary"
						type="button"
						style={{ zIndex: '0' }}
						onClick={() => {
							const value = validNumber(parseInt(number - step));
							setFinalNumber(value);
						}}
					>
						<i className="la la-lg la-minus"></i>
					</button>
				</div>
				<input
					type="text"
					name={name}
					value={number}
					className="form-control form-number"
					onChange={onChangeNumber}
				/>
				<div className="input-group-append">
					<button
						className="btn btn-secondary"
						type="button"
						style={{ zIndex: '0' }}
						onClick={() => {
							const value = validNumber(parseInt(number + step));
							setFinalNumber(value);
						}}
					>
						<i className="la la-lg la-plus"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

export default InputNumber;
