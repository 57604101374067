import actions from './actions';
import uiActions from '../ui/actions';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import * as client from '../../utils/client';

export const setAuthToken = token => {
	if (token) {
		// Apply to every request
		axios.defaults.headers.common['Authorization'] = token;
	} else {
		// Delete auth header
		delete axios.defaults.headers.common['Authorization'];
	}
};

export const login = (data, history) => dispatch => {
	client.exec({
		type: 'post',
		url: '/user/login',
		data,
		callback: function(res) {
			// Save to localStorage
			const { token } = res;
			// Set token to ls
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// Set current user
			dispatch(actions.setCurrentUser(decoded));
			dispatch(uiActions.clearErrors());
			history.push('/dashboard');
		}
	});
};

export const register = (data, history) => dispatch => {
	client.exec({
		type: 'post',
		url: '/user/register',
		data,
		callback: function(res) {
			// Save to localStorage
			const { token } = res;
			// Set token to ls
			localStorage.setItem('jwtToken', token);
			// Set token to Auth header
			setAuthToken(token);
			// Decode token to get user data
			const decoded = jwt_decode(token);
			// Set current user
			dispatch(actions.setCurrentUser(decoded));
			dispatch(uiActions.clearErrors());
			history.push('/dashboard');
		}
	});
};

export const logout = () => dispatch => {
	// Remove token from localStorage
	localStorage.removeItem('jwtToken');
	// Remove auth header for future requests
	setAuthToken(false);
	// Set current user to {} which will set isAuthenticated to false
	dispatch(actions.clearCurrentUser({}));
};

export const checkToken = () => dispatch => {
	if (localStorage.jwtToken) {
		// Set auth token header auth
		setAuthToken(localStorage.jwtToken);
		// Decode token and get user info and exp
		const decoded = jwt_decode(localStorage.jwtToken);
		// Set user and isAuthenticated
		dispatch(actions.setCurrentUser(decoded));
		// Check for expired token
		const currentTime = Date.now() / 1000;
		if (decoded.exp < currentTime) {
			// Logout user
			logout();
			// Redirect to login
			window.location.href = '/';
		}
	}
};

export const getLoggedUser = (id, loading = false) => {
	return dispatch =>
		client.exec({
			url: '/user/' + id,
			callback: function(res) {
				dispatch(actions.getLoggedUser(res));
			},
			loading
		});
};

export const clearResponse = () => dispatch => {
	dispatch(actions.clearResponse());
};

export const setAccessUrl = data => dispatch => {
	dispatch(actions.setAccessUrl(data));
};
