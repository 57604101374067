import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

const SessionViewPhotos = props => {
	const { photos } = props;
	const [modalDefault, setModalDefault] = useState(String);
	const [modalNative, setModalNative] = useState(String);
	const defaultPhotos = [];
	const nativePhotos = [];

	const toggleModalDefault = id => {
		setModalDefault(id);
	};

	const toggleModalNative = id => {
		setModalNative(id);
	};

	for (let i = 0; i < photos.length; i++) {
		const photo = photos[i];
		if (photo.photo_code > 99) {
			nativePhotos.push({ src: photo.cdn_url, title: photo.photo_code });
		} else {
			defaultPhotos.push({ src: photo.cdn_url, title: photo.photo_code });
		}
	}

	return (
		<div id="Session_photos">
			<ModalGateway>
				{!isNaN(parseInt(modalDefault)) ? (
					<Modal onClose={() => toggleModalDefault('')}>
						<Carousel
							views={defaultPhotos}
							currentIndex={modalDefault}
							frameProps={{ autoSize: 'height' }}
						/>
					</Modal>
				) : null}
			</ModalGateway>
			<ModalGateway>
				{!isNaN(parseInt(modalNative)) ? (
					<Modal onClose={() => toggleModalNative('')}>
						<Carousel
							views={nativePhotos}
							currentIndex={modalNative}
							frameProps={{ autoSize: 'height' }}
						/>
					</Modal>
				) : null}
			</ModalGateway>
			<div className="card">
				<div className="card-header">
					<h2 className="card-title">Standard Photos</h2>
				</div>
				<div className="card-body">
					<div className="row row-cols-2 row-cols-md-3">
						{defaultPhotos.map((photo, index) => {
							return (
								<div key={index} className="col mb-4">
									<div className="card">
										<div
											className="photo-box clickable"
											onClick={() =>
												toggleModalDefault(index)
											}
										>
											<img
												src={photo.src}
												alt={photo.title}
												className="card-img-top"
											/>
											<span>{photo.title}</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="card mt-3">
				<div className="card-header">
					<h2 className="card-title">Native Photos</h2>
				</div>
				<div className="card-body">
					<div className="row row-cols-2 row-cols-md-3">
						{nativePhotos.map((photo, index) => {
							return (
								<div key={index} className="col mb-4">
									<div className="card">
										<div
											className="photo-box clickable"
											onClick={() =>
												toggleModalNative(index)
											}
										>
											<img
												src={photo.src}
												alt={photo.title}
												className="card-img-top"
											/>
											<span>{index + 1}</span>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SessionViewPhotos;
