import axios from 'axios';
import store from '../store';
import uiActions from '../app/ui/actions';

const setBaseUrl = {
	client:
		process.env.REACT_APP_CLIENT_BASE_URL ||
		'https://client-dev.paveapi.com/api',
	capapi:
		process.env.REACT_APP_CAPAPI_BASE_URL ||
		'https://session-dev.paveapi.com/api',
	api: process.env.REACT_APP_API_BASE_URL || 'https://dash-dev.paveapi.com/v1'
};

export const exec = async ({
	type = 'get',
	url,
	data,
	callback,
	loading = true,
	baseUrlType = 'client'
}) => {
	const baseUrl = setBaseUrl[baseUrlType];
	if (loading) {
		store.dispatch(uiActions.setLoading());
	}
	let success = false;
	try {
		if (!['get', 'post', 'put', 'delete'].includes(type)) {
			const error = {
				response: { status: 405, message: 'Unsupported method.' }
			};
			throw error;
		}
		let req;
		if (type === 'get') {
			req = await axios.get(baseUrl + url, { params: data || {} });
		} else {
			req = await axios[type](baseUrl + url, data);
		}
		if (req.data) {
			callback(req.data);
			if (req.data.message) {
				store.dispatch(
					uiActions.setResponse({
						response: {
							status: req.data.status || req.status,
							message: req.data.message
						}
					})
				);
			}
		}
		success = true;
	} catch (e) {
		store.dispatch(uiActions.setErrors(e));
	}
	if (loading) {
		store.dispatch(uiActions.clearLoading());
	}
	return success;
};

export const download = async (
	url,
	params = {},
	callback,
	loading = true,
	baseUrlType = 'client'
) => {
	const baseUrl = setBaseUrl[baseUrlType];
	if (loading) {
		store.dispatch(uiActions.setLoading());
	}
	let success = false;
	try {
		const req = await axios({
			url: baseUrl + url, //your url
			params: params,
			method: 'GET',
			responseType: 'blob' // important
		});
		callback(req.data);
		success = true;
	} catch (e) {
		store.dispatch(uiActions.setErrors(e));
	}
	if (loading) {
		store.dispatch(uiActions.clearLoading());
	}
	return success;
};
