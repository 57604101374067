import * as types from './types';

const initialState = {
	theme: 'default',
	loading: false,
	errors: {},
	response: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.SET_LOADING:
			return {
				...state,
				loading: true
			};
		case types.SET_ERRORS:
			let errors = action.payload || {};
			if (errors.headers || errors.config) {
				delete errors.headers;
				delete errors.config;
			}
			if (typeof errors.data === 'string') {
				errors.message = errors.data;
			} else {
				errors.message =
					errors.data && errors.data.message
						? errors.data.message
						: 'There was a error while processing.';
			}
			return {
				...state,
				errors
			};
		case types.SET_RESPONSE:
			return {
				...state,
				response: action.payload
			};
		case types.CLEAR_ERRORS:
			return {
				...state,
				errors: {}
			};
		case types.CLEAR_LOADING:
			return {
				...state,
				loading: false
			};
		case types.CLEAR_RESPONSE:
			return {
				...state,
				response: {}
			};
		case types.CLEAR_ALL:
			return initialState;
		default:
			return state;
	}
}
