import React from 'react';
import InputText from '../../ui/components/InputText';

export default ({
	fields = [],
	errors,
	values,
	onChange,
	onSubmit,
	onReset,
	showLabel = false
}) => {
	return (
		<>
			{fields.map(field => {
				return (
					<InputText
						label={showLabel ? field.label : ''}
						key={field.key}
						placeholder={`${field.label} ${
							field.required ? '*' : '(Optional)'
						}`}
						name={field.key}
						value={values[field.key] || ''}
						onChange={onChange}
						error={errors[field.key]}
					/>
				);
			})}
			<div className="row mt-3">
				<div className="col">
					<button
						onClick={onSubmit}
						className="btn btn-block btn-primary"
					>
						Submit
					</button>
				</div>
				{onReset && (
					<div className="col">
						<button
							onClick={onReset}
							className="btn btn-block btn-light"
						>
							Reset
						</button>
					</div>
				)}
			</div>
		</>
	);
};
