import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import auth from './app/auth';
import ui from './app/ui';
import dealership from './app/dealerships';
import rep from './app/reps';
import session from './app/sessions';
import request from './app/requests';
import dashboard from './app/dashboard';

const rootReducer = combineReducers({
	ui,
	auth,
	dealership,
	rep,
	session,
	request,
	dashboard
});

const initialState = {};

const middleware = [thunk];
const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;

const enhancer = composeEnhancers(
	applyMiddleware(...middleware)
	// other store enhancers if any
);
const store = createStore(rootReducer, initialState, enhancer);

export default store;
