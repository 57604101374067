import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getList, createOne, uploadCsv, downloadCsv } from '../operations';
import { setResponse } from '../../ui/operations';
import DealershipTable from '../components/DealershipTable';
import DealershipForm from '../components/DealershipForm';
import DealershipAddFields from '../settings/DealershipAddFields';
import DisplayHeadButton from '../../ui/components/DisplayHeadButton';
import Paginator from '../../ui/components/Paginator';
import { hasErrors } from '../../../utils/validate';
import _pick from 'lodash/pick';

export const DealershipList = (props) => {
	const {
		auth,
		dealership,
		getList,
		createOne,
		uploadCsv,
		downloadCsv,
		setResponse,
	} = props;
	const { account } = auth;
	const [items, setItems] = useState(Array);
	const [pagination, setPagination] = useState(Object);
	const [filters, setFilters] = useState(Object);
	const [displayBox, setDisplayBox] = useState(String);
	const [dealershipInfo, setDealershipInfo] = useState(Object);
	const [csv, setCsv] = useState(Object);
	const [errors, setErrors] = useState(Object);
	const fields = DealershipAddFields;

	useEffect(() => {
		getList();
	}, [getList]);

	useEffect(() => {
		const data = dealership.list.data;
		const pagination = _pick(dealership.list, [
			'page',
			'pages',
			'count',
			'limit',
		]);
		setItems(data);
		setPagination(pagination);
	}, [dealership.list, setItems, setPagination]);

	const onDisplayBox = (box) => {
		if (displayBox !== box) {
			setDisplayBox(box);
		} else {
			setDisplayBox('');
		}
	};

	const onChangeInput = (e) => {
		setDealershipInfo({
			...dealershipInfo,
			[e.target.name]: e.target.value,
		});
	};

	const onResetDealershipInfo = (e) => {
		e.preventDefault();
		setDealershipInfo({});
	};

	const onAddDealership = async (e) => {
		e.preventDefault();
		const validate = hasErrors(fields, dealershipInfo);
		if (validate.status) {
			setErrors(validate.list);
		} else {
			setErrors({});
			const success = await createOne({
				...dealershipInfo,
				country: 'CA',
			});
			if (success) {
				setDealershipInfo({});
				getList();
				setResponse({
					response: {
						status: 200,
						message: `Dealership ${dealershipInfo.dealer_name} has been added.`,
					},
				});
			}
		}
	};

	const onSelectCsv = (e) => {
		if (e.target.files[0]) {
			setCsv(e.target.files[0]);
			setErrors({ ...errors, file: null });
		}
	};

	const onUploadCsv = async (e) => {
		e.preventDefault();
		if (csv && csv.name) {
			setErrors({ ...errors, file: null });
			let data = new FormData();
			data.append('file', csv);
			const success = await uploadCsv(data);
			if (success) {
				getList();
			}
		} else {
			setErrors({ ...errors, file: 'Please select a csv file.' });
		}
	};

	const onChangePaginator = ({ page, limit }) => {
		setPagination({ ...pagination, page, limit });
		getList({ page, limit });
	};

	const onChangeFilter = (e) => {
		setFilters({ searchKey: e.target.name, search: e.target.value });
	};

	const onFilter = (e) => {
		e.preventDefault();
		const { search } = filters;
		if (search) {
			getList({ search });
		} else {
			const { page, limit } = setPagination;
			getList({ page, limit });
		}
	};

	return (
		<section id="Dealership_list">
			<div className="heading">
				<h1>Dealership Manager</h1>
				{['USER', 'REP_MANAGER'].includes(account.role) && (
					<ul className="buttons">
						<li>
							<DisplayHeadButton
								buttonClass="primary"
								buttonLabel="Add new dealership"
								action={() => {
									onDisplayBox('add');
								}}
							/>
						</li>
						<li>
							<DisplayHeadButton
								buttonClass="success text-dark"
								buttonLabel="Upload dealership"
								icon="upload"
								action={() => {
									onDisplayBox('upload');
								}}
							/>
						</li>
						<li>
							<DisplayHeadButton
								buttonClass="warning"
								buttonLabel="Download dealership"
								icon="download"
								action={() => {
									downloadCsv();
								}}
							/>
						</li>
					</ul>
				)}
			</div>
			<div className="container-fluid">
				<div className="row">
					<div
						className={`${displayBox ? 'col-12 col-lg-3' : 'hide'}`}
					>
						<div
							className={`card mb-3 ${
								displayBox === 'add' ? 'show' : 'hide'
							}`}
						>
							<div className="card-header bg-primary">
								<h2 className="card-title text-white">
									<span>New dealership Information</span>
									<i
										className="la la-lg la-times float-right"
										onClick={() => setDisplayBox('')}
									></i>
								</h2>
							</div>
							<div className="card-body">
								<p>
									To add a new dealership to the database,
									please fill in all the fields below that are
									marked with an asterisks.
								</p>
								<DealershipForm
									fields={fields}
									errors={errors}
									values={dealershipInfo}
									onChange={onChangeInput}
									onSubmit={onAddDealership}
									onReset={onResetDealershipInfo}
								/>
							</div>
						</div>
						<div
							className={`card mb-3 ${
								displayBox === 'upload' ? 'show' : 'hide'
							}`}
						>
							<div className="card-header bg-success">
								<h2 className="card-title text-dark">
									<span>Upload dealerships</span>
									<i
										className="la la-lg la-times float-right"
										onClick={() => setDisplayBox('')}
									></i>
								</h2>
							</div>
							<div className="card-body">
								<p>
									If you have a CSV, please import dealership
									data by uploading your file. Required
									exactly header fields in order:{' '}
								</p>
								<p>
									<b>
										client_id, dealer_name, contact_name,
										contact_tel, address, city, province,
										country, postal_code, dealertrack_id,
										manheim_id, ddc_id
									</b>
								</p>
								<p>
									<i>*fields may be left empty if unknown*</i>
								</p>
								<div
									className={`input-group ${
										errors.file ? 'is-invalid' : ''
									}`}
								>
									<div className="custom-file">
										<input
											type="file"
											className={`custom-file-input ${
												errors.file ? 'is-invalid' : ''
											}`}
											id="Dealership_upload_file"
											aria-describedby="Dealership_upload_file_btn"
											accept=".csv"
											onChange={onSelectCsv}
										/>
										<label
											className={`custom-file-label ${
												errors.file ? 'is-invalid' : ''
											}`}
											htmlFor="Dealership_upload_file"
										>
											{csv && csv.name
												? csv.name
												: 'Select .csv file'}
										</label>
									</div>
									<div className="input-group-append">
										<button
											className="btn btn-success text-dark"
											type="button"
											id="Dealership_upload_file_btn"
											onClick={onUploadCsv}
										>
											<i className="la la-upload"></i>
											<span className="font-weight-bold">
												Upload
											</span>
										</button>
									</div>
								</div>
								{errors.file && (
									<div className="invalid-feedback">
										{errors.file}
									</div>
								)}
							</div>
						</div>
					</div>
					<div
						className={`${
							displayBox ? 'col-12 col-lg-9' : 'col-12'
						}`}
					>
						<div className="card">
							<div className="card-body">
								<Paginator
									{...pagination}
									action={onChangePaginator}
								/>
								<div className="row mb-3">
									<div className="col-12 col-md-3 col-lg-2">
										<div className="input-group">
											<input
												type="text"
												name="dealer_name"
												className="form-control"
												placeholder="Search dealership..."
												value={filters.search || ''}
												onChange={onChangeFilter}
												onKeyPress={(e) => {
													if (e.key === 'Enter') {
														onFilter(e);
													}
												}}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-outline-primary"
													onClick={onFilter}
												>
													<i className="la la-lg la-search"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<DealershipTable
									items={items}
									editable={true}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	dealership: state.dealership,
});

const mapDispatchToProps = {
	getList,
	createOne,
	uploadCsv,
	downloadCsv,
	setResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealershipList);
