import * as types from './types';

const initialState = {
	requests: {},
	groups: {},
	group: {},
	createdGroup: {},
	dealerships: {},
	dealership: {},
	sessions: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.GET_REQUESTS:
			return {
				...state,
				requests: action.payload
			};
		case types.GET_DEALERSHIPS:
			return {
				...state,
				dealerships: action.payload
			};
		case types.GET_DEALERSHIP:
			return {
				...state,
				dealership: action.payload
			};
		case types.GET_SESSION_GROUPS:
			return {
				...state,
				groups: action.payload
			};
		case types.GET_SESSION_GROUP:
			return {
				...state,
				group: action.payload
			};
		case types.CREATE_SESSION_GROUP:
			return {
				...state,
				createdGroup: action.payload
			};
		case types.CLEAR_SESSION_GROUP:
			return {
				...state,
				createdGroup: {}
			};
		default:
			return state;
	}
}
