import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';

const rbcaRules = {
	REP_MANAGER: [
		'dealerships',
		'dealershipView',
		'reps',
		'repView',
		'sessions',
		'sessionView',
		'sessionSendResults',
		'sessionGroup',
		'dashboard'
	],
	REP: ['dashboard', 'dealerships', 'sessions', 'sessionGroup', 'sessionView']
};

const commonPaths = ['dashboard', 'forbidden', 'notfound'];

const PrivateRoute = props => {
	const auth = props.auth;
	const Component = props.component;
	const match = props.computedMatch;

	return (
		<Route
			render={props => {
				const accessPath = props.location.pathname.split('/')[1];
				const { isAuthenticated, account } = auth;

				if (!isAuthenticated) {
					return <Redirect to="/" />;
				}
				if (
					accessPath &&
					!commonPaths.includes(accessPath) &&
					rbcaRules[account.role] &&
					!rbcaRules[account.role].includes(accessPath)
				) {
					return <Redirect to="/forbidden" />;
				}
				return (
					<>
						<Header {...props}></Header>
						<Component {...props} match={match} />
					</>
				);
			}}
		/>
	);
};

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
