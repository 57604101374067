import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from '../../../utils/string';

export default ({ items, editable = false, showUploader = false }) => {
	return (
		<div className="table-responsive">
			<table id="Dealership_table" className="table table-vmid bg-white">
				<thead>
					<tr>
						{editable && <th></th>}
						<th>DDC ID</th>
						<th>Manheim ID</th>
						<th>Dealertrack ID</th>
						<th>Dealership Name</th>
						<th>Address</th>
						<th>City</th>
						<th>Province</th>
						<th>Contact Name</th>
						<th>Contact #</th>
						{showUploader && <th>Uploader</th>}
					</tr>
				</thead>
				<tbody>
					{Array.isArray(items) &&
						items.map(item => (
							<tr key={uid()}>
								{editable && (
									<td>
										<Link
											to={`/dealershipView/${item.id}`}
											className="btn btn-outline-light"
										>
											<i className="la la-lg la-pencil text-primary"></i>
										</Link>
									</td>
								)}
								<td>{item.ddc_id}</td>
								<td>{item.manheim_id}</td>
								<td>{item.dealertrack_id}</td>
								<td>{item.dealer_name}</td>
								<td>{item.address}</td>
								<td>{item.city}</td>
								<td>{item.province}</td>
								<td>{item.contact_name}</td>
								<td>{item.contact_tel}</td>
								{showUploader && <td>Uploader</td>}
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};
