import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

import * as Sentry from '@sentry/browser';

Sentry.init({
	dsn: 'https://5149adbd3e6b409685149884845195d8@sentry.io/2084044',
	release: 'client-dashboard@' + process.env.npm_package_version,
	environment: process.env.NODE_ENV
});

ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
unregisterServiceWorker();
