import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLoggedUser, logout } from '../app/auth/operations';

const activeSubPages = {
	repView: 'reps',
	dealershipView: 'dealerships',
	sessionView: 'sessions',
	sessionGroup: 'dashboard'
};
const activeMenuItems = {
	USER: ['dealerships', 'reps', 'sessions', 'dashboard'],
	REP_MANAGER: ['dealerships', 'reps', 'sessions', 'dashboard'],
	REP: ['dealerships', 'sessions', 'dashboard']
};
const listMenu = [
	{
		id: 'dealerships',
		path: '/dealerships',
		icon: 'la-store-alt',
		label: 'Dealerships'
	},
	{
		id: 'reps',
		path: '/reps',
		icon: 'la-user-friends',
		label: 'Reps'
	},
	{
		id: 'sessions',
		path: '/sessions',
		icon: 'la-clipboard-list',
		label: 'Sessions'
	},
	{
		id: 'dashboard',
		path: '/dashboard',
		icon: 'la-tachometer-alt',
		label: 'Dashboard'
	}
];

export const Header = props => {
	const { auth, ui, getLoggedUser, logout } = props;
	let path = props.location.pathname.split('/')[1];
	if (activeSubPages[path]) {
		path = activeSubPages[path];
	}
	const [account, setAccount] = useState(Object);
	const [active, setActive] = useState(path);

	useEffect(() => {
		const { user, account } = auth;
		if (!account.id && user.id) {
			getLoggedUser(user.id);
		} else {
			setAccount(auth.account);
		}
	}, [auth, getLoggedUser]);

	if (!account.id) {
		return <></>;
	}

	return (
		<header id="Header" className={ui.theme}>
			<nav className="navbar navbar-expand-lg">
				<Link to="/" className="navbar-brand">
					<img
						src="/img/logo_pave_white.png"
						width="auto"
						height="32"
						className="d-inline-block align-top"
						alt="PAVE"
					/>
				</Link>
				<button
					className="navbar-toggler collapsed"
					type="button"
					data-toggle="collapse"
					data-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<i className="navbar-on la la-2x la-bars text-light"></i>
					<i className="navbar-off la la-2x la-times text-light"></i>
				</button>
				<div
					className="collapse navbar-collapse"
					id="navbarNavDropdown"
				>
					<ul className="navbar-nav">
						{listMenu.map(item => {
							if (
								activeMenuItems[account.role] &&
								!activeMenuItems[account.role].includes(item.id)
							) {
								return null;
							}
							return (
								<li
									key={`Sidebar_menu_${item.id}`}
									className={`nav-item ${
										active === item.id ? 'active' : ''
									}`}
								>
									<Link
										to={item.path}
										className="nav-link"
										onClick={() => {
											setActive(item.id);
										}}
									>
										<i
											className={`la la-lg ${item.icon}`}
										/>
										<span>{item.label}</span>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
				<ul id="navbarNav" className="navbar-nav">
					<li className="d-none d-sm-block">
						<span className="navbar-text">
							{(() => {
								if (account.username) {
									return (
										<>
											Welcome{' '}
											<span>{account.username}</span>
										</>
									);
								}
								return;
							})()}
						</span>
					</li>
					<li className="nav-item">
						<button
							onClick={logout}
							className="btn btn-link nav-link nav-fit text-danger"
						>
							<i className="la la-2x la-sign-out-alt"></i>
						</button>
					</li>
				</ul>
			</nav>
		</header>
	);
};

const mapStateToProps = state => ({
	auth: state.auth,
	ui: state.ui
});

export default connect(mapStateToProps, { getLoggedUser, logout })(Header);
