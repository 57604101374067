import * as types from './types';

const getList = (list) => {
	return {
		type: types.GET_LIST,
		payload: list,
	};
};
const createOne = (item) => {
	return {
		type: types.CREATE_ONE,
		payload: item,
	};
};

const getOne = (item) => {
	return {
		type: types.GET_ONE,
		payload: item,
	};
};

const editOne = (item) => {
	return {
		type: types.GET_ONE,
		payload: item,
	};
};

const uploadCsv = (item) => {
	return {
		type: types.UPLOAD_CSV,
		payload: item,
	};
};

export default {
	getList,
	createOne,
	getOne,
	editOne,
	uploadCsv,
};
