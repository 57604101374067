export const hasErrors = (fields, values) => {
	let list = {};
	for (let i = 0; i < fields.length; i++) {
		const field = fields[i];
		const value = values[field.key];
		if (field.required && isOneEmpty(value)) {
			list[field.key] = `${field.label} is missing.`;
		} else if (value && field.isEmail && !isEmail(value)) {
			list[field.key] = `${field.label} is not a valid email address.`;
		} else if (value && field.isPhone && !isPhone(value)) {
			list[field.key] = `${field.label} is not a valid phone number.`;
		} else if (value && field.isPassword && !isPassword(value)) {
			list[
				field.key
			] = `${field.label} should have 6-20 characters, which contain at least one numeric digit, one uppercase and one lowercase letter.`;
		}
	}
	const status = Object.keys(list).length > 0;
	return {
		status,
		list
	};
};

export const isOneEmpty = value => {
	return (
		value === undefined ||
		value === null ||
		(typeof value === 'object' && Object.keys(value).length === 0) ||
		(typeof value === 'string' && value.trim().length === 0)
	);
};

export const isEmail = value => {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		String(value).toLowerCase()
	);
};

export const isPhone = value => {
	return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(
		String(value).toLowerCase()
	);
};

export const isPassword = value => {
	return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(String(value));
};
