import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	getList,
	createOne,
	toggleActive,
	uploadReps,
	downloadReps
} from '../operations';
import { setResponse } from '../../ui/operations';
import RepTable from '../components/RepTable';
import RepForm from '../components/RepForm';
import RepAddRoleFields from '../settings/RepAddRoleFields';
import Paginator from '../../ui/components/Paginator';
import DisplayHeadButton from '../../ui/components/DisplayHeadButton';
import { hasErrors } from '../../../utils/validate';
import _pick from 'lodash/pick';

const getListParams = {
	sort: 'created_at',
	sortBy: 'desc'
};

export const RepList = props => {
	const {
		auth,
		rep,
		getList,
		createOne,
		toggleActive,
		uploadReps,
		downloadReps,
		setResponse
	} = props;
	const { account } = auth;
	const [items, setItems] = useState(Array);
	const [pagination, setPagination] = useState(Object);
	const [displayBox, setDisplayBox] = useState(String);
	const [accountInfo, setAccountInfo] = useState(Object);
	const [filters, setFilters] = useState(Object);
	const [errors, setErrors] = useState(Object);
	const [csv, setCsv] = useState(Object);
	const [activeButtonDate, setActiveButtonDate] = useState("");

	const buttonDates = [
		{
			value: "",
			label: "All Time",
		},
		{
			value: "day",
			label: "This Day",
		},
		{
			value: "week",
			label: "This Week",
		},
		{
			value: "month",
			label: "This Month",
		},
	];

	const fields = RepAddRoleFields;

	useEffect(() => {
		getList('users', getListParams);
	}, [getList]);

	useEffect(() => {
		setItems(rep.list.data || []);
		setPagination(_pick(rep.list, ['page', 'pages', 'count', 'limit']));
	}, [rep.list]);

	useEffect(() => {
		const { page, limit } = pagination;
		getList('users', { ...getListParams, ...filters, page, limit });
	}, [filters.date, getList, pagination.page, pagination.limit]);

	const onDisplayBox = box => {
		if (displayBox !== box) {
			setDisplayBox(box);
		} else {
			setDisplayBox('');
		}
	};

	const onChangeInput = e => {
		setAccountInfo({
			...accountInfo,
			[e.target.name]: e.target.value
		});
	};

	const onResetAccountInfo = e => {
		e.preventDefault();
		setAccountInfo({});
	};

	const onAddAccount = async e => {
		e.preventDefault();
		const validate = hasErrors(fields, accountInfo);
		if (validate.status) {
			setErrors(validate.list);
		} else {
			setErrors({});
			let data = accountInfo;
			const success = await createOne(data);
			if (success) {
				setAccountInfo({});
				getList('users', getListParams);
				setResponse({
					response: {
						status: 200,
						message: `Account ${data.username} with role ${data.role} has been created.`
					}
				});
			}
		}
	};

	const onToggleActive = async id => {
		const success = await toggleActive(id);
		if (success) {
			const { page, limit } = pagination;
			getList('users', { ...getListParams, page, limit });
		}
	};

	const onChangePaginator = ({ page, limit }) => {
		setPagination({ ...pagination, page, limit });
		getList('users', { ...getListParams, ...filters, page, limit });
	};

	const onSwitchDate = (e) => {
		setActiveButtonDate(e.target.value);
		setFilters({
			...filters,
			...{
				date: e.target.value,
			}
		});
	};

	const onChangeFilter = e => {
		setFilters({
			...filters,
			...{
				searchKey: e.target.name,
				search: e.target.value
			}
		});
	};

	const onFilter = e => {
		// e.preventDefault();
		const { page, limit } = pagination;
		getList('users', { ...getListParams, ...filters, page, limit });
	};

	const onSelectCsv = e => {
		if (e.target.files[0]) {
			setCsv(e.target.files[0]);
			setErrors({ ...errors, file: null });
		}
	};

	const onUploadCsv = async e => {
		e.preventDefault();
		if (csv && csv.name) {
			setErrors({ ...errors, file: null });
			let data = new FormData();
			data.append('file', csv);
			const success = await uploadReps(data);
			if (success) {
				getList('users', { ...getListParams });
			}
		} else {
			setErrors({ ...errors, file: 'Please select a csv file.' });
		}
	};

	return (
		<section id="Rep_list">
			<div className="heading">
				<h1>Rep Account Manager</h1>
				<ul className="buttons">
					<li>
						<DisplayHeadButton
							buttonClass="primary"
							buttonLabel="Add new account"
							action={() => {
								onDisplayBox('add');
							}}
						/>
					</li>
					<li>
						<DisplayHeadButton
							buttonClass="success text-dark"
							buttonLabel="Upload reps"
							icon="upload"
							action={() => {
								onDisplayBox('upload');
							}}
						/>
					</li>
					<li>
						<DisplayHeadButton
							buttonClass="warning"
							buttonLabel="Download reps"
							icon="download"
							action={() => {
								downloadReps();
							}}
						/>
					</li>
				</ul>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div
						className={`${displayBox ? 'col-12 col-lg-3' : 'hide'}`}
					>
						<div
							className={`card mb-3 ${
								displayBox === 'add' ? 'show' : 'hide'
							}`}
						>
							<div className="card-header bg-primary">
								<h2 className="card-title text-white">
									<span>Rep Account Information</span>
									<i
										className="la la-lg la-times float-right"
										onClick={() => setDisplayBox('')}
									></i>
								</h2>
							</div>
							<div className="card-body">
								<RepForm
									fields={fields}
									errors={errors}
									accountInfo={accountInfo}
									onChangeInput={onChangeInput}
								/>
								<div className="row mt-3">
									<div className="col">
										<button
											onClick={onAddAccount}
											className="btn btn-block btn-primary"
										>
											Submit
										</button>
									</div>
									<div className="col">
										<button
											onClick={onResetAccountInfo}
											className="btn btn-block btn-light"
										>
											Reset
										</button>
									</div>
								</div>
							</div>
						</div>
						<div
							className={`card mb-3 ${
								displayBox === 'upload' ? 'show' : 'hide'
							}`}
						>
							<div className="card-header bg-success">
								<h2 className="card-title text-dark">
									<span>Upload reps account</span>
									<i
										className="la la-lg la-times float-right"
										onClick={() => setDisplayBox('')}
									></i>
								</h2>
							</div>
							<div className="card-body">
								<p>
									If you have a CSV, please import reps
									account data by uploading your file.
									Required exactly header fields in order:{' '}
								</p>
								<p>
									<b>
										FIRST_NAME, LAST_NAME, USERNAME, EMAIL,
										ROLE (REP or REP_MANAGER)
									</b>
								</p>
								<div
									className={`input-group ${
										errors.file ? 'is-invalid' : ''
									}`}
								>
									<div className="custom-file">
										<input
											type="file"
											className={`custom-file-input ${
												errors.file ? 'is-invalid' : ''
											}`}
											id="Rep_upload_file"
											aria-describedby="Rep_upload_file_btn"
											accept=".csv"
											onChange={onSelectCsv}
										/>
										<label
											className={`custom-file-label ${
												errors.file ? 'is-invalid' : ''
											}`}
											htmlFor="Rep_upload_file"
										>
											{csv && csv.name
												? csv.name
												: 'Select .csv file'}
										</label>
									</div>
									<div className="input-group-append">
										<button
											className="btn btn-success text-dark"
											type="button"
											id="Rep_upload_file_btn"
											onClick={onUploadCsv}
										>
											<i className="la la-upload"></i>
											<span className="font-weight-bold">
												Upload
											</span>
										</button>
									</div>
								</div>
								{errors.file && (
									<div className="invalid-feedback">
										{errors.file}
									</div>
								)}
							</div>
						</div>
					</div>
					<div
						className={`${
							displayBox ? 'col-12 col-lg-9' : 'col-12'
						}`}
					>
						<div className="card">
							<div className="card-body">
								<Paginator
									{...pagination}
									action={onChangePaginator}
								/>
								<div className="row mb-3">
									<div className="col-12 col-md-6 col-lg-2">
										<div className="input-group">
											<input
												type="text"
												name="dealer_name"
												className="form-control"
												placeholder="Search rep..."
												value={filters.search || ''}
												onChange={onChangeFilter}
												onKeyPress={e => {
													if (e.key === 'Enter') {
														onFilter(e);
													}
												}}
											/>
											<div className="input-group-append">
												<button
													className="btn btn-outline-primary"
													onClick={onFilter}
												>
													<i className="la la-lg la-search"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-12">
										{
											buttonDates.map((button, index) => {
												const active = activeButtonDate === button.value ? "btn-success" : "btn-primary";
												return <button key={index} onClick={onSwitchDate} className={`btn mr-2 ${active}`} value={button.value}>{button.label}</button>
											})
										}
									</div>
								</div>
								<RepTable
									items={items}
									toggleActive={[
										'USER',
										'REP_MANAGER'
									].includes(account.role)}
									toggleActiveAction={onToggleActive}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	rep: state.rep,
	auth: state.auth
});

const mapDispatchToProps = {
	getList,
	createOne,
	toggleActive,
	uploadReps,
	downloadReps,
	setResponse
};

export default connect(mapStateToProps, mapDispatchToProps)(RepList);
