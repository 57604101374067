import React from 'react';
import { Link } from 'react-router-dom';
import DateTime from '../../ui/components/DateTime';
import { uid } from '../../../utils/string';
import { get } from "lodash";

export default ({ items, timezone, sendMuvit = false }) => {
	return (
		<div className="table-responsive">
			<table id="Session_table" className="table table-vmid bg-white">
				<thead>
					<tr>
						<th />
						{sendMuvit && <th>Muvit</th>}
						<th>Session Key</th>
						<th>Rep</th>
						<th>Dealership</th>
						<th>VIN</th>
						<th width="6%">City</th>
						<th width="6%">Province</th>
						<th className="text-center">User Damage</th>
						<th className="text-center">User Reject</th>
						<th>Capture Start</th>
						<th>Capture End</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(items) &&
						items.map(item => {
							let userDamage = 0;
							let userReject = 0;
							const detectedDamageJson = get(item, 'built_inspection.detected_damages_json', null);
							const detectedDamages = detectedDamageJson ? JSON.parse(detectedDamageJson) : null;
							if (detectedDamages && detectedDamages.detected_damages) {
								for (const damage of detectedDamages.detected_damages) {
									if (damage.user_response === 'reject') {
										userReject ++;
									}
									if (damage.source === 'user') {
										userDamage ++;
									}
								}
							}

							const city = get(item, 'session_group.dealership.city', '--');
							const province = get(item, 'session_group.dealership.province', '--');

							const index = uid();
							let buttonClass, buttonLabel;
							if (item.status === 'COMPLETE') {
								buttonClass = 'warning';
								buttonLabel = 'View Result';
							} else if (item.status === 'IDLE') {
								buttonClass = 'secondary';
								buttonLabel = item.status;
							} else {
								buttonClass = 'primary';
								buttonLabel = item.status;
							}

							let status = 'failed';
							let response = '';

							if (item.cron_api_log) {
								if (item.cron_api_log.success === 1) {
									status = 'sent';
								} else {
									if (item.cron_api_log.response) {
										response = item.cron_api_log.response;
									}
								}
							}
							return (
								<tr key={index}>
									<td>
										<Link
											to={`/sessionView/${item.session_key}`}
											className={`btn btn-outline-${buttonClass}`}
										>
											<small className="text-uc text-bold">
												{buttonLabel}
											</small>
										</Link>
									</td>
									{sendMuvit && (
										<td>
											{item.cron_api_log ? (
												<>
													{status === 'sent' ? (
														<Link
															to={`/sessionSendResults/${item.session_key}`}
															className={`btn btn-outline-success`}
														>
															<small className="text-uc text-bold">
																SENT
															</small>
														</Link>
													) : (
														<span
															className={
																'muvit-status ' +
																status
															}
														>
															<i className="cl-circle sla-fw mr-2" />
															{status}
															<div className="muvit-response">
																{response}
															</div>
														</span>
													)}
												</>
											) : (
												<button
													className="btn btn-outline-primary"
													onClick={() =>
														sendMuvit(
															item.session_key
														)
													}
												>
													<small className="text-uc text-bold">
														SEND
													</small>
												</button>
											)}
										</td>
									)}
									<td>{item.session_key}</td>
									<td>
										{item.session_group &&
											item.session_group.rep &&
											item.session_group.rep.username}
									</td>
									<td>
										{item.session_group &&
											item.session_group.dealership &&
											item.session_group.dealership
												.dealer_name}
									</td>
									<td>
										{item.vehicle && item.vehicle.vin
											? item.vehicle.vin
											: '--'}
									</td>
									<td>{ city }</td>
									<td>{ province }</td>
									<td className="text-center">{ userDamage }</td>
									<td className="text-center">{ userReject }</td>
									<td>
										<DateTime
											value={item.inspect_started_at}
											timezone={timezone}
											format="L LTS"
										/>
									</td>
									<td>
										<DateTime
											value={item.inspect_ended_at}
											timezone={timezone}
											format="L LTS"
										/>
									</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
