import React from 'react';
import { Link } from 'react-router-dom';

export default props => {
	const { heading, content } = props;
	return (
		<section id="Session_group_dialog">
			<div className="heading">
				<h1 className="text-left">{heading}</h1>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						{content}
						<p>Please contact your admin for more information.</p>
						<Link to="/dashboard" className="btn btn-warning">
							<i className="la la-lg la-angle-left"></i>
							<span>Back to dashboard</span>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};
