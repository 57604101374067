import React, { Component } from 'react';
import { connect } from 'react-redux';

class Loading extends Component {
	render() {
		const { loading } = this.props.ui;

		if (!loading) {
			return <></>;
		}

		return <div id="Loading" className="d-flex"></div>;
	}
}

const mapStateToProps = state => ({
	ui: state.ui
});

export default connect(mapStateToProps, {})(Loading);
