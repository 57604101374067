import actions from './actions';
import * as client from '../../utils/client';

export const getSessions = (status = false, data, loading = true) => {
	let newParams = data;
	if (status) {
		newParams.status = status.toUpperCase();
	}

	return dispatch =>
		client.exec({
			url: '/session/sessions',
			data: newParams,
			callback: function(res) {
				dispatch(actions.getList(res, status));
			},
			loading
		});
};

export const getSessionGroups = (data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/session/groups',
			data,
			callback: function(res) {
				dispatch(actions.getSessionGroups(res));
			},
			loading
		});
};

export const getSession = (sessionKey, data, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/session/detail/' + sessionKey,
			data,
			callback: function(res) {
				dispatch(actions.getSession(res));
			},
			loading
		});
};

export const getSessionResults = (sessionKey, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/muvit/inspection-result/' + sessionKey,
			data: null,
			callback: function(res) {
				dispatch(actions.getSessionResults(res));
			},
			loading
		});
};

export const getLicense = (id, loading = true) => {
	return dispatch =>
		client.exec({
			url: '/license/' + id,
			data: null,
			callback: function(res) {
				dispatch(actions.getLicense(res));
			},
			loading
		});
};

export const getCsv = () => {
	return () =>
		client.download(
			'/session/sessions/csv',
			{},
			function(res) {
			const url = window.URL.createObjectURL(new Blob([res]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'inspections_dealer_com.csv');
			document.body.appendChild(link);
			link.click();
		});
};

export const MonthlySessionCSV = (fileName, params = {}) => {
	return () =>
		client.download(
			'/session/sessions/monthly-csv',
			params,
			function(res) {
			const url = window.URL.createObjectURL(new Blob([res]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
		});
};

export const sendMuvit = (sessionKey, data = null, loading = true) => {
	return dispatch =>
		client.exec({
			type: 'post',
			url: '/muvit/inspection-result/' + sessionKey,
			data,
			callback: function(res) {
				dispatch(actions.getSession(res));
			},
			loading
		});
};
