import React, { useEffect, useRef, useState } from "react";
import * as client from "../../../utils/client";

const DropdownItems = ({ name, selectItem, layout = 'download' }) => {
	const [dealerships, setDealerships] = useState([]);
	const [visible, setVisible] = useState(false);
	const [search, setSearch] = useState('');
	const [filter, setFilter] = useState({});
	const dropdownRef = useRef(null);

	// click away listener
	useEffect(() => {
		document.addEventListener('mousedown', handleClick, false);
		return () => document.removeEventListener('mousedown', handleClick, false);
	}, []);

	useEffect(() => {
		client.exec({
			url: '/dealerships/short-search',
			data: filter,
			callback: function(res) {
				if (res && res.success) {
					setDealerships(res.dealerships)
				}
			}
		})
	}, [search, filter]);

	const handleClick = e => {
		if (dropdownRef.current.contains(e.target)) {
			return;
		}
		setVisible(false);

	};

	const handleChange = e => {
		const { value } = e.target;
		const { name } = e.target;

		const obj = {};
		obj[name] = value;
		setFilter({
			...filter,
			...obj
		});
		setSearch(value);
		if (!visible) {
			setVisible(true);
		}
		// if (value === '') {
		// 	selectItem({id: 0, city: '', province: ''})
		// }
		selectItem(value)
	};

	const onselectItem = item => {
		setSearch(item);
		setVisible(false);
	};

	return (
		<div className="position-relative">
			<div tabIndex="0" className="input_container">
				{layout === 'download' && (
					<input
						className="input-group-append border-warning btn rounded-0 shadow-none"
						type="text"
						placeholder={`Search ${name}`}
						name={name}
						value={search}
						onChange={handleChange}
						onFocus={() => {
							setVisible(true);
						}}
					/>
				)}
				{layout === 'normal' && (
					<input
						className="form-control inline mb-2"
						type="text"
						placeholder={`Search ${name}`}
						name={name}
						value={search}
						onChange={handleChange}

						onFocus={() => {
							setVisible(true);
						}}
					/>
				)}

			</div>
			<div ref={dropdownRef} className={`position-absolute dropdown ${visible ? 'v' : ''}`}>
				{visible && (
					<ul>

						{dealerships.length > 0 && dealerships.map(x => (
							<li
								key={x.id}
								onClick={
									() => {
										onselectItem(x[name]);
										selectItem(x[name])
									}
								}
								className="dropdown_item"
							>
								<div className="item_text1">{x[name]}</div>
							</li>
						))}
						{dealerships.length === 0 && (
							<li key="zxc" className="dropdown_item">
								No result
							</li>
						)}
					</ul>
				)}
			</div>
		</div>
	);
};

export default DropdownItems;
