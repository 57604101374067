import React from 'react';
//import InputText from '../InputText';
import InputNumber from '../InputNumber';
import { uid } from '../../../../utils/string';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import SelectBox from '../SelectBox';
export default ({
	fields = [],
	errors,
	values,
	list = {},
	onChangeInput,
	showLabel = true,
	filterSearchDealer,
	onChangeFilterSearchDealer,
	showListSearchDealer,
	filterSearchRep,
	onChangeFilterSearchRep,
	showListSearchRep,
	onOffListDealer,
	onOffListRep,
}) => {
	return (
		<>
			{fields.map((field) => {
				if (field.depend && !values[field.depend]) {
					return false;
				}
				const index = uid();
				switch (field.key) {
					case 'dealership_id':
						return (
							<div className="form-group" key="search-dealer">
								<label style={{ display: 'block' }}>
									{field.label}
								</label>
								<div
									className="input-group"
									style={{ position: 'relative' }}
								>
									<input
										type="text"
										name="dealer_name"
										className="form-control"
										placeholder="Search dealership..."
										autoComplete="off"
										value={
											filterSearchDealer &&
											filterSearchDealer.search
												? filterSearchDealer.search
												: ''
										}
										onChange={(e) =>
											onChangeFilterSearchDealer(e)
										}
									/>
									<i
										className="las la-angle-down"
										onClick={onOffListDealer}
										style={{
											position: 'absolute',
											top: '10px',
											right: '5px',
										}}
									></i>
									{showListSearchDealer ? (
										<div
											className="list-search-dealer"
											style={{
												position: 'absolute',
												width: '100%',
												height: '175px',
												top: '40px',
												left: '0',
												background: '#fff',
												overflow: 'scroll',
												borderRadius: '5px',
												zIndex: '99',
												border: '1px solid #e9ecef',
												padding: '0 10px',
											}}
										>
											{list[field.key].length > 0 &&
												list[field.key].map((item) => (
													<div
														onClick={(e) =>
															onChangeInput(
																e,
																field.key,
																item.key,
																item.label
															)
														}
														key={`item-search=${item.key}`}
														className="item"
														style={{
															cursor: 'pointer',
															padding: '5px 0',
														}}
														hover={{
															background: 'red',
															color: '#fff',
														}}
													>
														{item.label}
													</div>
												))}
										</div>
									) : null}
								</div>
								{errors[field.key] && (
									<div className="invalid-feedback">
										{errors[field.key]}
									</div>
								)}
							</div>
						);
					case 'rep_id':
						return (
							<div className="form-group" key="search-rep">
								<label style={{ display: 'block' }}>
									{field.label}
								</label>
								<div
									className="input-group"
									style={{ position: 'relative' }}
								>
									<input
										type="text"
										name="rep_name"
										className="form-control"
										placeholder="Search Rep"
										autoComplete="off"
										value={
											filterSearchRep &&
											filterSearchRep.search
												? filterSearchRep.search
												: ''
										}
										onChange={(e) =>
											onChangeFilterSearchRep(e)
										}
									/>
									<i
										className="las la-angle-down"
										onClick={onOffListRep}
										style={{
											position: 'absolute',
											top: '10px',
											right: '5px',
										}}
									></i>
									{showListSearchRep ? (
										<div
											className="list-search-dealer"
											style={{
												position: 'absolute',
												width: '100%',
												height: '175px',
												top: '40px',
												left: '0',
												background: '#fff',
												overflow: 'scroll',
												borderRadius: '5px',
												zIndex: '99',
												border: '1px solid #e9ecef',
												padding: '0 10px',
											}}
										>
											{list[field.key].length > 0 &&
												list[field.key].map((item) => (
													<div
														onClick={(e) =>
															onChangeInput(
																e,
																field.key,
																item.key,
																item.label
															)
														}
														key={`item-search=${item.key}`}
														className="item"
														style={{
															cursor: 'pointer',
															padding: '5px 0',
														}}
														hover={{
															background: 'red',
															color: '#fff',
														}}
													>
														{item.label}
													</div>
												))}
										</div>
									) : null}
								</div>
								{errors[field.key] && (
									<div className="invalid-feedback">
										{errors[field.key]}
									</div>
								)}
							</div>
						);
					case 'time_of_date':
						return (
							<SelectBox
								key={index}
								label={showLabel ? field.label : ''}
								empty={field.empty}
								list={list[field.key] || []}
								name={field.key}
								value={
									values[field.key]
										? String(values[field.key])
										: field.defaultValue || ''
								}
								onChange={onChangeInput}
								error={errors[field.key]}
							/>
						);
					case 'number_vehicles':
						const value = parseInt(values[field.key]);
						return (
							<InputNumber
								key={index}
								label={showLabel ? field.label : ''}
								name={field.key}
								value={!isNaN(value) ? value : 1}
								onChangeTotal={onChangeInput}
							></InputNumber>
						);
					case 'todo_date':
						const selectedValue = values[field.key]
							? new Date(values[field.key])
							: '';
						return (
							<div key={index} className="form-group">
								{showLabel && <label>{field.label}</label>}
								<DatePicker
									className={`form-control ${
										errors[field.key] && 'is-invalid'
									}`}
									name={field.key}
									minDate={new Date()}
									selected={selectedValue}
									onChange={(date) => {
										var n = date.toISOString();
										const parseDate = moment(
											n,
											moment.ISO_8601
										);
										onChangeInput({
											target: {
												name: field.key,
												value: parseDate.format(
													'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
												),
											},
										});
									}}
								/>
								{errors[field.key] && (
									<div className="small text-danger mt-1">
										{errors[field.key]}
									</div>
								)}
							</div>
						);
					case 'contact_name':
					case 'contact_number':
					default:
						return (
							<div className="form-group" key={field.key}>
								{field.label && <label>{field.label}</label>}
								<input
									type="text"
									className={`form-control ${
										errors[field.key] ? 'is-invalid' : ''
									}`}
									placeholder={`${field.label} ${
										field.required ? '*' : '(Optional)'
									}`}
									name={field.key}
									value={
										values[field.key]
											? String(values[field.key])
											: ''
									}
									onChange={onChangeInput}
									autoComplete={field.key}
								/>
								{errors[field.key] && (
									<div className="invalid-feedback">
										{errors[field.key]}
									</div>
								)}
							</div>
							// <InputText
							// 	key={index}
							// 	label={showLabel ? field.label : ''}
							// 	placeholder={`${field.label} ${
							// 		field.required ? '*' : '(Optional)'
							// 	}`}
							// 	name={field.key}
							// 	value={
							// 		values[field.key]
							// 			? String(values[field.key])
							// 			: ''
							// 	}
							// 	onChange={onChangeInput}
							// 	error={errors[field.key]}
							// />
						);
				}
			})}
		</>
	);
};
