import React from 'react';
import { uid } from '../../../../utils/string';

const Card = ({ title, titleButtons, body }) => {
	const cardId = uid();

	return (
		<div className="card mb-3">
			<div className="card-header">
				<h2 className="card-title">
					{title}
					{titleButtons}
				</h2>
				<button
					className="btn btn-link btn-toggle"
					data-toggle="collapse"
					data-target={`#${cardId}_panel`}
					aria-expanded="true"
					aria-controls={`${cardId}_panel`}
				>
					<i className="la la-lg"></i>
				</button>
			</div>
			<div
				className="card-body show"
				id={`${cardId}_panel`}
				aria-labelledby={`${cardId}_panel`}
			>
				<div>{body}</div>
			</div>
		</div>
	);
};

export default Card;
