import React from 'react';
import Moment from 'react-moment';
import 'moment-timezone';

const DateTime = ({ value, timezone = false, format = 'D MMM YYYY LTS' }) => {
	Moment.globalFormat = format;
	if (!value || (value && value.includes('0001-01-01'))) {
		return <span>--</span>;
	}
	return <Moment tz={timezone}>{value}</Moment>;
};

export default DateTime;
