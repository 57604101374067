import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import store from './store';
// Styles
import './App.scss';
// Components
import ErrorBoundary from './ErrorBoundary';
import PrivateRoute from './components/PrivateRoute';
import { Auth } from './app/auth';
import { UI } from './app/ui';
import { Dealership } from './app/dealerships';
import { Rep } from './app/reps';
import { Session } from './app/sessions';
import { Dashboard } from './app/dashboard';

// Check for token
import authActions from './app/auth/actions';
import { setAuthToken, logout } from './app/auth/operations';
if (localStorage.jwtToken) {
	// Set auth token header auth
	setAuthToken(localStorage.jwtToken);
	// Decode token and get user info and exp
	const decoded = jwt_decode(localStorage.jwtToken);
	// Set user and isAuthenticated
	store.dispatch(authActions.setCurrentUser(decoded));

	// Check for expired token
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		// Logout user
		store.dispatch(logout());
		// Redirect to login
		window.location.href = '/';
	}
}

class App extends Component {
	render() {
		return (
			<ErrorBoundary>
				<Provider store={store}>
					<UI.Loading />
					<UI.Alert />
					<Router>
						<Switch>
							<Route exact path="/" component={Auth.Login} />
							<Route
								exact
								path="/register"
								component={Auth.Register}
							/>
							<PrivateRoute
								exact
								path="/dealerships"
								component={Dealership.List}
							/>
							<PrivateRoute
								exact
								path="/dealershipView/:id"
								component={Dealership.View}
							/>
							<PrivateRoute
								exact
								path="/reps"
								component={Rep.List}
							/>
							<PrivateRoute
								exact
								path="/repView/:id"
								component={Rep.View}
							/>
							<PrivateRoute
								exact
								path="/sessions"
								component={Session.List}
							/>
							<PrivateRoute
								exact
								path="/sessionView/:sessionKey"
								component={Session.View}
							/>
							<PrivateRoute
								exact
								path="/sessionSendResults/:sessionKey"
								component={Session.SendResults}
							/>
							<PrivateRoute
								exact
								path="/dashboard"
								component={Dashboard.Home}
							/>
							<PrivateRoute
								exact
								path="/sessionGroup/:id/:sessionKey?"
								component={Dashboard.SessionGroup}
							/>
							<PrivateRoute
								exact
								path="/forbidden"
								component={UI.Forbidden}
							/>
							<Route component={UI.NotFound} />
						</Switch>
					</Router>
				</Provider>
			</ErrorBoundary>
		);
	}
}

export default App;
