import React from 'react';
import PropTypes from 'prop-types';

const InputText = ({
	name,
	placeholder,
	value,
	label,
	error,
	info,
	type,
	onChange,
	onFocus,
	disabled,
}) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<input
				type={type}
				className={`form-control ${error ? 'is-invalid' : ''}`}
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				disabled={disabled}
				autoComplete={name}
			/>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

InputText.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	info: PropTypes.string,
	error: PropTypes.string,
	type: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	disabled: PropTypes.string,
};

InputText.defaultProps = {
	type: 'text',
};

export default InputText;
