import React from 'react';

export const DisplayHeadButton = props => {
	const { buttonClass, buttonLabel, icon, action, hideOnMobile } = props;

	return (
		<button className={`btn btn-${buttonClass}`} onClick={action}>
			<i className={`la la-lg la-${icon}`}></i>
			<span className={hideOnMobile ? 'd-none d-sm-inline' : ''}>
				{buttonLabel}
			</span>
		</button>
	);
};

DisplayHeadButton.defaultProps = {
	buttonClass: 'primary',
	buttonLabel: 'Do something',
	hideOnMobile: true,
	icon: 'plus'
};

export default DisplayHeadButton;
