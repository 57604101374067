import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	editRequest,
	deleteRequest,
	completeRequest,
} from '../../requests/operations';
import { getList as getDealerships } from '../../dealerships/operations';
import { getList as getReps } from '../../reps/operations';
import Form from '../../ui/components/Form';
import DateTime from '../../ui/components/DateTime';
import fields from '../settings/RequestFields';
import { uid } from '../../../utils/string';

import { hasErrors } from '../../../utils/validate';
import _pick from 'lodash/pick';
import $ from 'jquery';

const defaultList = {
	time_of_date: [
		{ key: 'Morning', label: 'Morning' },
		{
			key: 'Afternoon',
			label: 'Afternoon',
		},
		{ key: 'All day', label: 'All day' },
	],
};

const defaultParams = { limit: 50 };
const editFields = [
	'dealership_id',
	'rep_id',
	'number_vehicles',
	'todo_date',
	'time_of_date',
	'contact_name',
	'contact_number',
];
const numberFields = ['dealership_id', 'rep_id', 'number_vehicles'];

const RequestTable = (props) => {
	const {
		dealership,
		getDealerships,
		getReps,
		editRequest,
		deleteRequest,
		editable = true,
		items,
		rep,
		refresh,
		refreshParams,
		timezone,
		completeRequest,
	} = props;
	const [errors, setErrors] = useState(Object);
	const [fieldList, setFieldList] = useState({ ...defaultList });
	const [editValues, setEditValues] = useState(Object);
	const [filterSearchDealer, setfilterSearchDealer] = useState(Object);
	const [filterSearchRep, setfilterSearchRep] = useState(Object);
	const [showListSearchDealer, setshowListSearchDealer] = useState(false);
	const [showListSearchRep, setshowListSearchRep] = useState(false);
	useEffect(() => {
		if (!dealership.list.data) {
			getDealerships({ ...defaultParams });
		}
	}, [dealership.list, getDealerships]);

	useEffect(() => {
		if (!rep.list.data) {
			getReps('reps', { ...defaultParams });
		}
	}, [rep.list, getReps]);

	useEffect(() => {
		if (
			dealership.list.data &&
			dealership.list.data.length > 0 &&
			!fieldList.dealership_id
		) {
			let dealership_id = [];
			for (let i = 0; i < dealership.list.data.length; i++) {
				dealership_id.push({
					key: dealership.list.data[i].id,
					label: dealership.list.data[i].dealer_name,
				});
			}
			setFieldList({ ...fieldList, dealership_id });
		}
	}, [dealership.list, fieldList]);

	useEffect(() => {
		if (rep.list.data && rep.list.data.length > 0 && !fieldList.rep_id) {
			let rep_id = [];
			for (let i = 0; i < rep.list.data.length; i++) {
				rep_id.push({
					key: rep.list.data[i].id,
					label: `${rep.list.data[i].username} (${rep.list.data[i].firstname} ${rep.list.data[i].lastname})`,
				});
			}
			setFieldList({ ...fieldList, rep_id });
		}
	}, [rep.list, fieldList]);

	const onChangeInput = (e, nameSearch, valueSearch, label) => {
		let name = '';
		let value = '';
		if (name || valueSearch || label) {
			name = nameSearch;
			value = valueSearch;
			switch (name) {
				case 'dealership_id':
					setfilterSearchDealer({
						searchKey: nameSearch,
						search: label,
					});
					setshowListSearchDealer(false);
					break;
				case 'rep_id':
					setfilterSearchRep({
						searchKey: nameSearch,
						search: label,
					});
					setshowListSearchRep(false);
					break;
				default:
					break;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}
		if (numberFields.includes(name)) {
			value = parseInt(value);
		}
		setEditValues({ ...editValues, [e.target.name]: value });
	};

	const onEdit = (item) => {
		setfilterSearchDealer({
			searchKey: 'dealership_id',
			search:
				item.dealership && item.dealership.dealer_name
					? item.dealership.dealer_name
					: '',
		});
		setfilterSearchRep({
			searchKey: 'rep_id',
			search:
				item.rep && item.rep.firstname && item.rep.lastname
					? item.rep.firstname + item.rep.lastname
					: '',
		});
		setEditValues(item);
	};

	const onDelete = (id) => {
		setEditValues({ id });
	};
	const onEditSubmit = async (e) => {
		e.preventDefault();
		const error = hasErrors(fields, editValues);
		if (error.status) {
			setErrors(error.list);
		} else {
			setErrors({});
			if (editValues.id) {
				const success = await editRequest(
					editValues.id,
					_pick(editValues, editFields)
				);
				if (success) {
					refresh({ ...refreshParams });
					$('#Modal_edit_close').click();
				}
			}
		}
	};

	const onDeleteConfirm = async (e) => {
		e.preventDefault();
		if (editValues.id) {
			const success = await deleteRequest(editValues.id);
			if (success) {
				refresh({ ...refreshParams });
				$('#Modal_delete_close').click();
			}
		}
	};
	const onCompletedConfirm = async (id) => {
		if (id) {
			const success = await completeRequest(id);
			if (success) {
				refresh({ ...refreshParams, status: 'INCOMPLETE' });
			}
		}
	};
	const onChangeFilterSearchDealer = (e) => {
		setfilterSearchDealer({
			searchKey: e.target.name,
			search: e.target.value,
		});
		setshowListSearchDealer(true);
		getDealerships({
			search: e.target.value,
			searchKey: 'dealer_name',
			limit: 25,
		});
	};
	const onChangeFilterSearchRep = (e) => {
		e.preventDefault();
		setfilterSearchRep({
			searchKey: e.target.name,
			search: e.target.value,
		});
		setshowListSearchRep(true);
		getReps('reps', {
			search: e.target.value,
			limit: 25,
		});
	};
	const list =
		Array.isArray(items) &&
		items.map((item) => {
			const index = uid();
			const dealership = item.dealership || {};
			const rep = item.rep || {};
			let status = {};
			if (item.status === 'COMPLETED') {
				status.class = 'success';
				status.label = 'Complete';
			} else if (item.status === 'IN-QUEUE') {
				status.class = 'secondary';
				status.label = 'Not Started';
			} else {
				status.class = 'info';
				status.label = 'In Progress';
			}
			return (
				<tr key={index}>
					{editable && (
						<td>
							{item.status === 'IN-QUEUE' && (
								<div className="btn-group">
									<button
										className="btn btn-outline-light"
										data-toggle="modal"
										data-target="#Modal_edit"
										onClick={() => {
											onEdit(item);
										}}
									>
										<i className="la la-lg la-pencil text-primary"></i>
									</button>
									<button
										className="btn btn-outline-light"
										data-toggle="modal"
										data-target="#Modal_delete"
										onClick={() => {
											onDelete(item.id);
										}}
									>
										<i className="la la-lg la-trash text-danger"></i>
									</button>
								</div>
							)}
							{item.status === 'PROCESSING' && (
								<button
									className="btn btn-outline-light"
									onClick={() => {
										onCompletedConfirm(item.id);
									}}
								>
									<i className="la la-lg la-check text-success"></i>
								</button>
							)}
						</td>
					)}
					<td>
						<span
							className={`small text-uppercase text-bold text-${status.class}`}
						>
							{status.label}
						</span>
					</td>
					<td>
						{dealership.dealer_name || '--'}
						<br />
						<small>{dealership.address || '--'}</small>
					</td>
					<td>{rep.username || '--'}</td>

					<td className="text-right">
						<DateTime
							value={item.todo_date}
							format="ddd, MMM DD, YYYY"
						/>
						<br></br>
						{item.time_of_date}
					</td>
					<td className="text-right">
						{item.session_group && item.session_group.progress
							? item.session_group.progress
							: 0}
					</td>
					<td className="text-right">{item.number_vehicles}</td>
					<td>
						{item.contact_name}
						<br></br>
						{item.contact_number}
					</td>
					<td className="text-right">
						<DateTime
							value={item.created_at}
							timezone={timezone}
							format="L LTS"
						/>
					</td>
					<td className="text-right">
						<DateTime
							value={item.updated_at}
							timezone={timezone}
							format="L LTS"
						/>
					</td>
				</tr>
			);
		});
	return (
		<>
			<div className="table-responsive">
				<table id="Request_table" className="table table-vmid">
					<thead>
						<tr>
							{editable && <th></th>}
							<th>Status</th>
							<th>Dealership</th>
							<th>Rep</th>
							<th className="text-right">Date/Time</th>
							<th className="text-right">Captured</th>
							<th className="text-right"># of Vehicles</th>
							<th className="text-right">Contact</th>
							<th className="text-right">Created At</th>
							<th className="text-right">Updated At</th>
						</tr>
					</thead>
					<tbody>{list}</tbody>
				</table>
			</div>
			<div
				id="Modal_edit"
				className="modal fade"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<Form
								fields={fields}
								values={editValues}
								list={fieldList}
								errors={errors}
								onChangeInput={onChangeInput}
								timezone={timezone}
								filterSearchDealer={filterSearchDealer}
								filterSearchRep={filterSearchRep}
								onChangeFilterSearchDealer={(e) =>
									onChangeFilterSearchDealer(e)
								}
								onChangeFilterSearchRep={(e) =>
									onChangeFilterSearchRep(e)
								}
								showListSearchRep={showListSearchRep}
								showListSearchDealer={showListSearchDealer}
							></Form>
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-primary"
								onClick={onEditSubmit}
							>
								<span className="text-uppercase text-bold">
									Submit
								</span>
							</button>
							<button
								id="Modal_edit_close"
								className="btn btn-outline-danger"
								data-dismiss="modal"
							>
								<span className="text-uppercase text-bold">
									Close
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				id="Modal_delete"
				className="modal fade"
				tabIndex="-1"
				role="dialog"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-body">
							<p>Please confirm to delete this request.</p>
						</div>
						<div className="modal-footer">
							<button
								className="btn btn-danger"
								onClick={onDeleteConfirm}
							>
								<span className="text-uppercase text-bold">
									Yes, I Confirm
								</span>
							</button>
							<button
								id="Modal_delete_close"
								className="btn btn-outline-secondary"
								data-dismiss="modal"
							>
								<span className="text-uppercase text-bold">
									Close
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	dealership: state.dealership,
	rep: state.rep,
	request: state.request,
});

const mapDispatchToProps = {
	getDealerships,
	getReps,
	editRequest,
	deleteRequest,
	completeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTable);
