import React from 'react';
import BackButton from '../components/BackButton';

export default () => {
	return (
		<section id="Rep_list">
			<div className="heading">
				<h1 className="text-left">403 Forbidden</h1>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col">
						<p>You don't have permission to access this page.</p>
						<BackButton />
					</div>
				</div>
			</div>
		</section>
	);
};
