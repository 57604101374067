import * as types from './types';

const getList = (list, status) => {
	let type = 'GET_LIST';
	if (status && ['COMPLETE', 'INCOMPLETE'].includes(status.toUpperCase())) {
		type += '_' + status.toUpperCase();
	}
	return {
		type: types[type],
		payload: list
	};
};

const getSessionGroups = list => {
	return {
		type: types.GET_SESSION_GROUPS,
		payload: list
	};
};

const getSession = data => {
	return {
		type: types.GET_SESSION,
		payload: data
	};
};

const getSessionResults = data => {
	return {
		type: types.GET_SESSION_RESULTS,
		payload: data
	};
};

const getLicense = data => {
	return {
		type: types.GET_LICENSE,
		payload: data
	};
};

const getNativePhotos = data => {
	return {
		type: types.GET_NATIVE_PHOTOS,
		payload: data
	};
};

const createRequest = data => {
	return {
		type: types.CREATE_REQUEST,
		payload: data
	};
};

export default {
	getList,
	getSessionGroups,
	getSession,
	getSessionResults,
	getLicense,
	getNativePhotos,
	createRequest
};
