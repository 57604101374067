export default [
	{
		key: 'firstname',
		label: 'First Name',
		required: true
	},
	{
		key: 'lastname',
		label: 'Last Name',
		required: true
	},
	{
		key: 'email',
		label: 'Email Address',
		required: true,
		isEmail: true
	},
	{
		key: 'username',
		label: 'Username',
		required: true
	},
	{
		key: 'password',
		label: 'Password',
		required: true,
		isPassword: true
	},
	{
		key: 'role',
		label: 'Role',
		required: true,
		type: 'select',
		list: [
			{
				key: 'REP_MANAGER',
				label: 'Dealer Manager'
			},
			{
				key: 'REP',
				label: 'Dealer Rep'
			}
		]
	}
];
