import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login } from '../operations';
import InputText from '../../ui/components/InputText';

class Login extends Component {
	constructor() {
		super();
		this.state = {
			login: '',
			password: '',
			errors: {}
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.ui.errors && nextProps.ui.errors.data) {
			this.setState({ errors: nextProps.ui.errors.data || {} });
		}
	}

	componentDidMount() {
		if (this.props.auth.isAuthenticated) {
			this.props.history.push('/dashboard');
		}
	}

	onSubmit = e => {
		e.preventDefault();

		const userData = {
			login: this.state.login,
			password: this.state.password
		};
		this.props.login(userData, this.props.history);
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const { errors } = this.state;

		return (
			<div className="login mt-5">
				<div className="container">
					<div className="row">
						<div className="col-md-6 m-auto">
							<div className="brand text-center mb-4">
								<img
									src="img/logo_cox_canada.png"
									width="auto"
									height="40"
									alt="Cox Automotive"
								/>
							</div>
							<div className="card">
								<div className="card-header">
									<h1 className="card-title">
										Sign in to dashboard
									</h1>
								</div>
								<div className="card-body">
									<form
										onSubmit={this.onSubmit}
										className=""
										autoComplete="off"
									>
										<InputText
											label="Username / Email Address"
											placeholder="Enter your account username or email address"
											name="login"
											value={this.state.login}
											onChange={this.onChange}
											error={errors.login}
										/>

										<InputText
											label="Password"
											placeholder="Enter your account password"
											name="password"
											type="password"
											value={this.state.password}
											onChange={this.onChange}
											error={errors.password}
										/>

										<button
											type="submit"
											className="btn btn-primary btn-block mt-4"
										>
											<i className="fa fa-lg fa-sign-in" />
											<span>Sign Me In</span>
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Login.propTypes = {
	login: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth,
	ui: state.ui
});

export default connect(mapStateToProps, { login })(Login);
