import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSession } from '../operations';
import SessionViewInfo from '../components/SessionViewInfo';
import SessionViewVehicle from '../components/SessionViewVehicle';
import SessionViewPhotos from '../components/SessionViewPhotos';

export const SessionView = props => {
	const { session, getSession } = props;
	const { sessionKey } = props.match.params;
	const [item, setItem] = useState(Object);

	useEffect(() => {
		getSession(sessionKey);
	}, [sessionKey, getSession]);

	useEffect(() => {
		setItem(session.item);
	}, [session.item, setItem]);

	return (
		<section id="Session_view">
			<div className="heading">
				<h1>
					<span className="text-light">Inspection Report: </span>
					<span className="text-bold">{item.session_key}</span>
				</h1>
				<ul className="buttons">
					<li>
						<Link to="/sessions" className="btn btn-primary">
							<i className="la la-lg la-angle-left"></i>
							<span className="d-none d-sm-inline">Go Back</span>
						</Link>
					</li>
				</ul>
			</div>

			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-lg-6">
						<div className="card mb-3">
							<div className="card-header">
								<h2 className="card-title">
									Session Information
								</h2>
							</div>
							<div className="card-body">
								<SessionViewInfo item={item} {...props} />
							</div>
						</div>
						<div className="card mb-3">
							<div className="card-header">
								<h2 className="card-title">Vehicle Data</h2>
							</div>
							<div className="card-body">
								<SessionViewVehicle
									vehicle={item.vehicle || {}}
								/>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-6">
						<SessionViewPhotos photos={item.photos || []} />
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	session: state.session,
	auth: state.auth
});

const mapDispatchToProps = { getSession };

export default connect(mapStateToProps, mapDispatchToProps)(SessionView);
