import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from '../../../utils/string';

export default ({
	items,
	showRole = true,
	toggleActive = false,
	toggleActiveAction = false
}) => {
	return (
		<div className="table-responsive">
			<table id="Rep_table" className="table table-vmid bg-white">
				<thead>
					<tr>
						<th/>
						{toggleActive && <th>Status</th>}
						<th>Username</th>
						<th>First</th>
						<th>Last</th>
						<th>Email</th>
						<th className="text-center">User Damages</th>
						<th className="text-center">User Rejects</th>
						{showRole && <th>Role</th>}
					</tr>
				</thead>
				<tbody>
					{Array.isArray(items) &&
						items.map(item => {
							const sessionInfos = item.session_infos ? item.session_infos : [];
							let userRejects = 0;
							let userDamages = 0;
							let totalDamage = 0;
							for (const sessionInfo of sessionInfos) {
								const { user_data } = sessionInfo;
								if (user_data) {
									const userData = JSON.parse(user_data);
									totalDamage += userData.total_damage;
									userRejects += userData.user_rejects.length;
									userDamages += userData.user_damages.length;
								}
							}

							const percentUserDamage = totalDamage && userDamages ? (userDamages / totalDamage) * 100 : 0;
							const percentUserReject = totalDamage && userRejects ? (userRejects / totalDamage) * 100 : 0;
							const index = uid();
							return (
								<tr key={index}>
									<td className="no-wrap">
										<Link
											to={`/repView/${item.id}`}
											className="btn btn-outline-light"
										>
											<i className="la la-lg la-pencil text-primary"/>
										</Link>
									</td>
									{toggleActive && (
										<td>
											<div
												className="clickable"
												onClick={() => {
													toggleActiveAction(item.id);
												}}
											>
												{item.status === 'ACTIVE' ? (
													<i className="la la-2x la-toggle-on text-success"/>
												) : (
													<i className="la la-2x la-toggle-off text-danger"/>
												)}
											</div>
										</td>
									)}
									<td>{item.username}</td>
									<td>{item.firstname}</td>
									<td>{item.lastname}</td>
									<td>{item.email}</td>
									<td className="text-center">{Math.round(percentUserDamage * 10) / 10}%</td>
									<td className="text-center">{Math.round(percentUserReject * 10) / 10}%</td>
									{showRole && <td>{item.role}</td>}
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
