import React from 'react';
import PropTypes from 'prop-types';
const SelectBox = ({
	name,
	empty,
	list,
	value,
	label,
	error,
	info,
	onChange,
	onFocus,
	disabled,
}) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<select
				className={`custom-select ${error ? 'is-invalid' : ''}`}
				name={name}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				disabled={disabled}
				autoComplete={name}
			>
				{empty && <option value="">{empty}</option>}
				{list.length > 0 &&
					list.map((item) => (
						<option key={item.key} value={item.key}>
							{item.label}
						</option>
					))}
			</select>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

SelectBox.propTypes = {
	name: PropTypes.string.isRequired,
	empty: PropTypes.string,
	list: PropTypes.array,
	info: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	disabled: PropTypes.string,
};

SelectBox.defaultProps = {
	list: [],
};

export default SelectBox;
