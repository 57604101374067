import React from 'react';
import PropTypes from 'prop-types';

const Paginator = (props) => {
	const { id, page, pages, count, action, fixedLimit } = props;
	let limit = props.limit;
	if (fixedLimit) {
		limit = fixedLimit;
	}

	const listSize = [10, 20, 50];
	const from = (page - 1) * limit;
	let to = page * limit;
	if (to > count) {
		to = count;
	}

	const onChange = (e) => {
		let limit;
		if (fixedLimit) {
			limit = fixedLimit;
		} else {
			if (e.target.getAttribute('data-value')) {
				limit = parseInt(e.target.getAttribute('data-value'), limit);
			}
		}
		let page = 1;
		if (e.target.getAttribute('data-page')) {
			page = parseInt(e.target.getAttribute('data-page'), 10);
		} else if (e.target.value) {
			page = parseInt(e.target.value, 10);
		}
		if (page > pages) {
			page = pages;
		} else if (page < 1) {
			page = 1;
		}

		action({ page, limit });
	};

	if (!count || count === 0) return <></>;
	return (
		<nav id={id} aria-label="Page navigation" className="row mb-3">
			<div className="col">
				{pages > 1 && (
					<ul className="pagination">
						<li
							className={`page-item d-none d-md-block ${
								page === 1 ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={onChange}
								aria-label="First"
								data-page={1}
							>
								<span aria-hidden="true">
									<i className="la la-lg la-step-backward" />
								</span>
								<span className="sr-only">First</span>
							</button>
						</li>
						<li
							className={`page-item ${
								page === 1 ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={onChange}
								aria-label="Previous"
								data-page={page > 2 ? page - 1 : 1}
							>
								<span aria-hidden="true">
									<i className="la la-lg la-angle-left" />
								</span>
								<span className="sr-only">Previous</span>
							</button>
						</li>
						<li className={`page-item`}>
							<div className="input-group">
								<input
									id="Current_page"
									type="number"
									className="form-control"
									aria-label="Current_page"
									aria-describedby="Current_page"
									value={page}
									onChange={onChange}
									min="1"
									max={pages}
									step="1"
								/>
								<div className="input-group-append d-none d-md-block">
									<span
										className="input-group-text font-weight-bold"
										id="Total_pages"
									>
										of {pages}
									</span>
								</div>
							</div>
						</li>
						<li
							className={`page-item ${
								page === pages ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={onChange}
								aria-label="Next"
								data-page={page < pages ? page + 1 : pages}
							>
								<span aria-hidden="true">
									<i className="la la-lg la-angle-right" />
								</span>
								<span className="sr-only">Next</span>
							</button>
						</li>
						<li
							className={`page-item d-none d-md-block ${
								page === pages ? 'disabled' : ''
							}`}
						>
							<button
								className="page-link"
								onClick={onChange}
								aria-label="Last"
								data-page={pages}
							>
								<span aria-hidden="true">
									<i className="la la-lg la-step-forward" />
								</span>
								<span className="sr-only">Last</span>
							</button>
						</li>
					</ul>
				)}
			</div>
			<div className="col text-center">
				{!fixedLimit && (
					<div
						className="btn-group btn-group-toggle"
						data-toggle="buttons"
					>
						{listSize.map((item) => (
							<label
								key={`Size_${item}`}
								className={`btn ${
									limit === item ? 'btn-primary' : 'btn-light'
								}`}
								data-value={item}
								onClick={onChange}
							>
								<input
									type="radio"
									name="limit"
									id="Page_size"
									autoComplete="off"
									value={item}
									defaultChecked={limit === item}
								/>{' '}
								{item}
							</label>
						))}
					</div>
				)}
			</div>
			<div className="col d-none d-md-block">
				<span
					className="input-group-text text-secondary small"
					id="Total_records"
				>
					Display {from}-{to} of total {count} records
				</span>
			</div>
		</nav>
	);
};

Paginator.propTypes = {
	page: PropTypes.number,
	pages: PropTypes.number,
	records: PropTypes.number,
	limit: PropTypes.number,
	action: PropTypes.func,
};

Paginator.defaultProp = {
	id: 'Paginator',
	page: 1,
	pages: 0,
	records: 0,
	limit: 10,
};

export default Paginator;
