import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getOne, editOne } from '../operations';
import { setResponse } from '../../ui/operations';
import DealershipForm from '../components/DealershipForm';
import DealershipAddFields from '../settings/DealershipAddFields';
import RepTable from '../../reps/components/RepTable';
import { hasErrors } from '../../../utils/validate';

export const DealershipView = props => {
	const { dealership, getOne, editOne, setResponse } = props;
	const { id } = props.match.params;
	const [item, setItem] = useState(Object);
	const [errors, setErrors] = useState(Object);
	const fields = DealershipAddFields;

	useEffect(() => {
		getOne(id);
	}, [id, getOne]);

	useEffect(() => {
		setItem(dealership.current || {});
	}, [dealership, setItem]);

	const onChange = e => {
		setItem({
			...item,
			[e.target.name]: e.target.value
		});
	};

	const onSubmit = async e => {
		e.preventDefault();
		const validate = hasErrors(fields, item);
		if (validate.status) {
			setErrors(validate.list);
		} else {
			setErrors({});
			const success = await editOne(id, item);
			if (success) {
				setResponse({
					response: {
						status: 200,
						message: 'All changes has been saved.'
					}
				});
			}
		}
	};

	return (
		<section id="Dealership_view">
			<div className="heading">
				<h1>
					{item.dealer_name} ({item.client_id})
				</h1>
				<ul className="buttons">
					<li>
						<Link to="/dealerships" className="btn btn-primary">
							<i className="la la-lg la-angle-left"></i>
							<span className="d-none d-sm-inline">Go Back</span>
						</Link>
					</li>
				</ul>
			</div>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-md-6 col-lg-4">
						<div className="card">
							<div className="card-body">
								<DealershipForm
									fields={fields}
									errors={errors}
									values={item}
									onChange={onChange}
									onSubmit={onSubmit}
									showLabel={true}
								/>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-8">
						<div className="card">
							<div className="card-header">
								<h2 className="card-title">
									Assigned Rep Accounts
								</h2>
							</div>
							<div className="card-body">
								<RepTable
									items={item.assigned_users || []}
									showRole={false}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = state => ({
	dealership: state.dealership
});

const mapDispatchToProps = { getOne, editOne, setResponse };

export default connect(mapStateToProps, mapDispatchToProps)(DealershipView);
