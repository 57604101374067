import * as types from './types';

const getList = list => {
	return {
		type: types.GET_LIST,
		payload: list
	};
};

const createOne = item => {
	return {
		type: types.CREATE_ONE,
		payload: item
	};
};

const getOne = item => {
	return {
		type: types.GET_ONE,
		payload: item
	};
};

const editOne = item => {
	return {
		type: types.EDIT_ONE,
		payload: item
	};
};

const editOneRole = item => {
	return {
		type: types.EDIT_ONE_ROLE,
		payload: item
	};
};

const activeDealership = item => {
	return {
		type: types.ACTIVE_DEALERSHIP,
		payload: item
	};
};

const uploadReps = item => {
	return {
		type: types.UPLOAD_REPS,
		payload: item
	};
};

export default {
	getList,
	createOne,
	getOne,
	editOne,
	editOneRole,
	activeDealership,
	uploadReps
};
