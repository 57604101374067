import * as types from './types';

const initialState = {
	list: {},
	complete: {},
	incomplete: {},
	groups: {},
	item: {},
	results: {},
	photos: [],
	license: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case types.GET_LIST:
			return {
				...state,
				list: action.payload
			};
		case types.GET_LIST_COMPLETE:
			return {
				...state,
				complete: action.payload
			};
		case types.GET_LIST_INCOMPLETE:
			return {
				...state,
				incomplete: action.payload
			};
		case types.GET_SESSION_GROUPS:
			return {
				...state,
				groups: action.payload
			};
		case types.GET_SESSION:
			return {
				...state,
				item: action.payload
			};
		case types.GET_SESSION_RESULTS:
			return {
				...state,
				results: action.payload
			};
		case types.GET_LICENSE:
			return {
				...state,
				license: action.payload
			};
		case types.GET_NATIVE_PHOTOS:
			return {
				...state,
				photos: action.payload
			};
		default:
			return state;
	}
}
