import * as types from './types';

const initialState = {
	list: {},
	current: {},
	upload: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_LIST:
			return {
				...state,
				list: action.payload,
			};
		case types.CREATE_ONE:
		case types.GET_ONE:
		case types.EDIT_ONE:
			return {
				...state,
				current: action.payload,
			};
		case types.UPLOAD_CSV:
			return {
				...state,
				current: action.payload,
			};
		default:
			return state;
	}
}
