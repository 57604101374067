import React, { Component } from 'react';
import { withRouter } from 'react-router';

class BackButton extends Component {
	render() {
		const { history } = this.props;
		return (
			<div>
				<button
					className="btn btn-primary"
					onClick={() => history.goBack()}
				>
					Go Back
				</button>
			</div>
		);
	}
}

export default withRouter(BackButton);
