export default [
	{
		type: 'search',
		key: 'dealership_id',
		label: 'Dealership Name',
		empty: '( select a dealership )',
		required: true,
	},
	{
		type: 'select',
		key: 'rep_id',
		label: 'Dealer Rep',
		empty: '( select a rep )',
		required: true,
	},
	{
		type: 'number',
		key: 'number_vehicles',
		label: 'Number of Vehicles',
		required: true,
	},
	{
		type: 'datepicker',
		key: 'todo_date',
		label: 'Date',
		required: true,
	},
	{
		type: 'select',
		key: 'time_of_date',
		label: 'Time of day',
		defaultValue: 'Morning',
		required: true,
	},
	{
		key: 'contact_name',
		label: 'Contact Name',
	},
	{
		key: 'contact_number',
		label: 'Contact Number',
		isPhone: true,
	},
];
