import React, { useState, useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDealerships, createSessionGroup } from '../operations';
import { getSessions, getSessionGroups } from '../../sessions/operations';
import { getRequests } from '../../requests/operations';
import { getList as getPageDealerships } from '../../dealerships/operations';
import DealershipTable from '../../dealerships/components/DealershipTable';
import RequestTableRep from '../../requests/components/RequestTableRep';
import SessionGroupTable from '../components/SessionGroupTable';
import SessionTable from '../../sessions/components/SessionTable';
//import SelectBox from '../../ui/components/SelectBox';
import InputNumber from '../../ui/components/InputNumber';
import Card from '../../ui/components/Card';
import _find from 'lodash/find';
import Paginator from '../../ui/components/Paginator';
import _pick from 'lodash/pick';
const defaultParams = { limit: 5 };
const defaultRequestParams = { sort: 'todo_date', sortBy: 'asc' };
const defaultGroupParams = { status: 'INCOMPLETE' };
const initialPaving = { number_vehicles: 1 };
const defaultPageFields = ['page', 'pages', 'count', 'limit'];
export const DashboardHome = (props) => {
	const {
		auth,
		session,
		dashboard,
		request,
		dealership,
		getRequests,
		getPageDealerships,
		getDealerships,
		createSessionGroup,
		getSessions,
		getSessionGroups,
	} = props;
	const { account } = auth;
	const [requests, setRequests] = useState(Array);
	const [dealerships, setDealerships] = useState(Array);
	const [groups, setGroups] = useState(Array);
	const [sessions, setSessions] = useState(Array);
	const [selectDealerships, setSelectDealerships] = useState(Array);
	const [displayBox, setDisplayBox] = useState(String);
	const [startPaving, setStartPaving] = useState(initialPaving);
	const [allowMuvit, setAllowMuvit] = useState(false);
	const [filterSearchDealer, setfilterSearchDealer] = useState(Object);
	const [showListSearchDealer, setshowListSearchDealer] = useState(false);
	const [incompletePagi, setIncompletePagi] = useState(Object);
	useEffect(() => {
		getRequests({ ...defaultParams, ...defaultRequestParams }, false);
		getPageDealerships({ ...defaultParams }, false);
		getSessions('complete', { ...defaultParams }, false);
		getSessionGroups({ ...defaultParams, ...defaultGroupParams }, false);
	}, [getRequests, getPageDealerships, getSessions, getSessionGroups]);

	useEffect(() => {
		setDealerships(dealership.list.data || []);
	}, [dealership.list, setDealerships]);

	useEffect(() => {
		setSessions(session.complete.data || []);
	}, [session.complete, setSessions]);

	useEffect(() => {
		setGroups(session.groups.data || []);
		const pageInfo = _pick(session.groups, defaultPageFields);
		if (pageInfo.page) {
			setIncompletePagi(pageInfo);
		}
	}, [session.groups, setGroups, session.page]);

	useEffect(() => {
		setRequests(request.list.data || []);
	}, [request.list, setRequests]);

	useEffect(() => {
		const data = dashboard.dealerships.data || [];
		let list = [];
		for (let i = 0; i < data.length; i++) {
			const item = data[i];
			list.push({
				key: item.id,
				label: item.dealer_name,
				manheim_id: item.manheim_id ? item.manheim_id : null,
			});
		}
		setSelectDealerships(list);
	}, [dashboard.dealerships, setSelectDealerships]);
	const onChangeIncompletePagi = ({ page }) => {
		setIncompletePagi({ ...incompletePagi, page });
		getSessionGroups({ ...defaultGroupParams, page: page, limit: 5 });
	};
	useEffect(() => {
		const dealership_id = parseInt(startPaving.dealership_id);
		if (!isNaN(dealership_id)) {
			const dealership = _find(selectDealerships, {
				key: dealership_id,
			});
			setAllowMuvit(dealership && dealership.manheim_id);
		}
	}, [startPaving.dealership_id, selectDealerships]);

	const onDisplayBox = async (box) => {
		if (displayBox !== box) {
			if (box === 'paving') {
				setfilterSearchDealer({});
				await getDealerships({ limit: 50 }, false);
				setStartPaving(initialPaving);
				setDisplayBox(box);
			}
		} else {
			setDisplayBox('');
		}
	};
	const onChangeFilterSearchDealer = (e) => {
		e.preventDefault();
		setfilterSearchDealer({
			searchKey: e.target.name,
			search: e.target.value,
		});
		setshowListSearchDealer(true);
		getDealerships({
			searchKey: 'dealer_name',
			search: e.target.value,
			limit: 25,
		});
	};
	const onChangePaving = (e, nameSearch, valueSearch, label) => {
		let name = '';
		let value = '';
		if (name || valueSearch || label) {
			name = nameSearch;
			value = `${valueSearch}`;
			switch (name) {
				case 'dealership_id':
					setfilterSearchDealer({
						searchKey: nameSearch,
						search: label,
					});
					setshowListSearchDealer(false);
					break;
				default:
					break;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}
		setStartPaving({
			...startPaving,
			[name]: parseInt(value),
		});
	};

	const onStartPaving = async (theme) => {
		if (theme) {
			createSessionGroup({ ...startPaving, theme });
		}
	};

	const onStartRequest = async (request) => {
		//setfilterSearchDealer({});
		if (request) {
			setfilterSearchDealer({
				searchKey: 'dealer_id',
				search:
					request.dealership && request.dealership.dealer_name
						? request.dealership.dealer_name
						: '',
			});
		}
		setStartPaving({
			...startPaving,
			request_id: request.id,
			dealership_id: request.dealership_id,
			number_vehicles: request.number_vehicles,
		});
		setDisplayBox('paving-request');
	};

	const { createdGroup } = dashboard;
	if (createdGroup.id) {
		return <Redirect to={`/sessionGroup/${createdGroup.id}`} />;
	}

	return (
		<section id="Dashboard_home">
			<div className="heading">
				<h1>Dashboard</h1>
				{['REP_MANAGER', 'REP'].includes(account.role) && (
					<ul className="buttons">
						<li>
							<button
								className="btn btn-warning"
								onClick={() => onDisplayBox('paving')}
							>
								<i className="la la-lg la-tablet"></i>
								<span>Start New Capture</span>
							</button>
						</li>
					</ul>
				)}
			</div>
			<div className="container-fluid">
				<div className="row">
					<div
						className={`${displayBox ? 'col-12 col-lg-3' : 'hide'}`}
					>
						{['REP_MANAGER', 'REP'].includes(account.role) && (
							<div
								className={`card mb-3 ${
									displayBox === 'paving' ||
									displayBox === 'paving-request'
										? 'show'
										: 'hide'
								}`}
							>
								<div
									className={`card-header ${
										startPaving.request_id
											? 'bg-dark'
											: 'bg-warning'
									}`}
								>
									<h2 className="card-title">
										<span
											className={`${
												startPaving.request_id
													? 'text-white'
													: ''
											}`}
										>
											{startPaving.request_id
												? 'PAVE Session Request'
												: 'PAVE Session Start'}
										</span>
										<i
											className="la la-lg la-times float-right clickable"
											onClick={() => setDisplayBox('')}
										></i>
									</h2>
								</div>
								<div className="card-body">
									<p>
										{startPaving.request_id
											? 'Please review the request and change the number of captures if needed.'
											: 'To start your capture(s), please select the dealership where the captures are occurring, and the number of captures you will be doing.'}
									</p>
									<div
										className="form-group"
										key="search-dealer"
									>
										<label style={{ display: 'block' }}>
											Dealership Name
										</label>
										<div
											className="input-group"
											style={{ position: 'relative' }}
										>
											<input
												type="text"
												name="dealer_name"
												className="form-control"
												placeholder="Search dealership..."
												autoComplete="off"
												value={
													filterSearchDealer &&
													filterSearchDealer.search
														? filterSearchDealer.search
														: ''
												}
												onChange={(e) =>
													onChangeFilterSearchDealer(
														e
													)
												}
											/>
											<i
												className="las la-angle-down"
												onClick={() =>
													setshowListSearchDealer(
														!showListSearchDealer
													)
												}
												style={{
													position: 'absolute',
													top: '10px',
													right: '5px',
												}}
											></i>
											{showListSearchDealer ? (
												<div
													className="list-search-dealer"
													style={{
														position: 'absolute',
														width: '100%',
														height: '175px',
														top: '40px',
														left: '0',
														background: '#fff',
														overflow: 'scroll',
														borderRadius: '5px',
														zIndex: '99',
														border:
															'1px solid #e9ecef',
														padding: '0 10px',
													}}
												>
													{selectDealerships.length >
														0 &&
														selectDealerships.map(
															(item) => (
																<div
																	onClick={(
																		e
																	) =>
																		onChangePaving(
																			e,
																			'dealership_id',
																			item.key,
																			item.label
																		)
																	}
																	key={`item-search=${item.key}`}
																	className="item"
																	style={{
																		cursor:
																			'pointer',
																		padding:
																			'5px 0',
																	}}
																	hover={{
																		background:
																			'red',
																		color:
																			'#fff',
																	}}
																>
																	{item.label}
																</div>
															)
														)}
												</div>
											) : null}
										</div>
									</div>
									<InputNumber
										label="Number of Captures"
										name="number_vehicles"
										value={startPaving.number_vehicles || 1}
										onChangeTotal={onChangePaving}
									/>
									{!isNaN(startPaving.dealership_id) && (
										<>
											<button
												className="btn btn-block btn-warning mt-3"
												onClick={() => {
													onStartPaving('LITE');
												}}
											>
												<span>
													Start for <b>Dealer.com</b>
												</span>
											</button>
											{allowMuvit ? (
												<button
													className="btn btn-block btn-primary mt-3"
													onClick={() => {
														onStartPaving(
															'DEFAULT'
														);
													}}
												>
													<span>
														Start for <b>MUVIT</b>
													</span>
												</button>
											) : (
												<>
													<button
														className="btn btn-block btn-primary mt-3"
														disabled={true}
													>
														<span>
															Start MUVIT is
															disabled
														</span>
													</button>
													<div className="alert alert-warning small mt-2 mb-0">
														<div className="mb-2">
															This dealership
															can't start MUVIT
															due missing of
															manheim_id.
														</div>
														{account.role ===
															'REP_MANAGER' && (
															<div>
																<Link
																	to={`/dealershipView/${startPaving.dealership_id}`}
																	className="text-bold"
																>
																	Add
																	manheim_id
																</Link>
															</div>
														)}
													</div>
												</>
											)}
										</>
									)}
								</div>
							</div>
						)}
					</div>
					<div
						className={`${
							displayBox ? 'col-12 col-lg-9' : 'col-12'
						}`}
					>
						{account.role === 'REP' && (
							<Card
								title={
									<>
										<i className="text-primary la la-fw la-lg la-calendar-check"></i>
										<span>Assigned Requests</span>
									</>
								}
								titleButtons={
									<Link to="/sessions" className="ml-3">
										<small className="text-bold text-info">
											view all
										</small>
									</Link>
								}
								body={
									<RequestTableRep
										items={requests}
										refresh={getRequests}
										refreshParams={{
											...defaultParams,
											...defaultRequestParams,
										}}
										onStart={onStartRequest}
										timezone={
											auth.account &&
											auth.account.timezone
										}
									/>
								}
							/>
						)}
						<Card
							title={
								<>
									<i className="text-primary la la-fw la-lg la-clipboard-check"></i>
									<span>Completed Sessions</span>
								</>
							}
							titleButtons={
								<Link to="/sessions" className="ml-3">
									<small className="text-bold text-info">
										view all
									</small>
								</Link>
							}
							body={<SessionTable items={sessions} />}
						/>
						<Card
							title={
								<>
									<i className="text-primary la la-fw la-lg la-clipboard-list"></i>
									<span>Incomplete Sessions</span>
								</>
							}
							body={
								<>
									<Paginator
										{...incompletePagi}
										action={onChangeIncompletePagi}
										fixedLimit={5}
									/>
									<SessionGroupTable
										items={groups}
										timezone={
											auth.account &&
											auth.account.timezone
										}
									/>
								</>
							}
						/>
						<Card
							title={
								<>
									<i className="text-primary la la-fw la-lg la-store-alt"></i>
									<span>Your Dealerships</span>
								</>
							}
							titleButtons={
								<Link to="/dealerships" className="ml-3">
									<small className="text-bold text-info">
										view all
									</small>
								</Link>
							}
							body={<DealershipTable items={dealerships} />}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

const mapStateToProps = (state) => ({
	dashboard: state.dashboard,
	session: state.session,
	auth: state.auth,
	request: state.request,
	dealership: state.dealership,
});

const mapDispatchToProps = {
	getRequests,
	getDealerships,
	getPageDealerships,
	createSessionGroup,
	getSessions,
	getSessionGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
