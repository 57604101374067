import React from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../components/BackButton';
import '../styles/NotFound.scss';

export default () => {
	return (
		<section id="NotFound">
			<p>
				<Link to="/">
					<img
						src="/img/logo_pave_blue.png"
						width="auto"
						height="30"
						alt="PAVE"
					/>
				</Link>
			</p>
			<h1 className="mt-4 text-primary">404 Not Found</h1>
			<p>Whoops, we are lost...</p>
			<BackButton />
		</section>
	);
};
