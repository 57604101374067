import React from 'react';

const SessionViewVehicle = props => {
	const { vehicle } = props;

	return (
		<div id="Session_vehicle">
			<div className="row">
				<div className="col-6">
					<div className="form-group">
						<label>VIN</label>
						<div className="text-bold">{vehicle.vin}</div>
					</div>
					<div className="form-group">
						<label>Year Make Model</label>
						<div>
							{vehicle.year} {vehicle.make} {vehicle.model}
						</div>
					</div>
					<div className="form-group">
						<label>Body Type</label>
						<div>{vehicle.body_type}</div>
					</div>
				</div>
				<div className="col-6">
					{vehicle.int_color && (
						<div className="form-group">
							<label>Interior</label>
							<div>
								{vehicle.int_color.color_name} (
								{vehicle.int_color.color_code})
							</div>
						</div>
					)}
					{vehicle.ext_color && (
						<div className="form-group">
							<label>Exterior</label>
							<div>
								<span
									className="color-block"
									style={{
										background:
											'#' + vehicle.ext_color.hex_value
									}}
								></span>
								{vehicle.ext_color.color_name} (
								{vehicle.ext_color.color_code})
							</div>
						</div>
					)}
					{vehicle.paved_trim && (
						<div className="form-group">
							<label>Trim</label>
							<br></br>
							{vehicle.paved_trim.trim}
						</div>
					)}
					<div className="form-group">
						<label>Odometer / Type</label>
						<br></br>
						{vehicle && vehicle.odom_reading} /{' '}
						{vehicle && vehicle.odom_unit}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SessionViewVehicle;
