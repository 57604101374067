import React from 'react';
import InputText from '../../ui/components/InputText';
import SelectBox from '../../ui/components/SelectBox';

export default ({ fields = [], errors, accountInfo, onChangeInput }) => {
	return (
		<>
			{fields.map((field, index) => {
				if (field.type === 'select') {
					return (
						<SelectBox
							key={index}
							label={field.showLabel ? field.label : ''}
							empty={`Select ${field.label} ${
								field.required ? '*' : '(Optional)'
							}`}
							list={field.list || []}
							name={field.key}
							value={accountInfo[field.key] || ''}
							onChange={onChangeInput}
							error={errors[field.key]}
						/>
					);
				}
				return (
					<InputText
						key={index}
						label={field.showLabel ? field.label : ''}
						placeholder={`${field.label} ${
							field.required ? '*' : '(Optional)'
						}`}
						name={field.key}
						value={accountInfo[field.key] || ''}
						onChange={onChangeInput}
						error={errors[field.key]}
					/>
				);
			})}
		</>
	);
};
