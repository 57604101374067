export default [
	{
		key: 'firstname',
		label: 'First Name',
		required: true,
		showLabel: true
	},
	{
		key: 'lastname',
		label: 'Last Name',
		required: true,
		showLabel: true
	},
	{
		key: 'email',
		label: 'Email Address',
		required: true,
		showLabel: true,
		isEmail: true
	},
	{
		key: 'username',
		label: 'Username',
		required: true,
		showLabel: true
	}
];
