import React from 'react';
import _some from 'lodash/some';
import { uid } from '../../../utils/string';

export default ({ items, dealerships, onChange }) => {
	return (
		<div className="table-responsive">
			<table id="Dealership_table" className="table bg-white">
				<thead>
					<tr>
						<th></th>
						<th>DDC ID</th>
						<th>Manheim ID</th>
						<th>Dealership</th>
						<th>Address</th>
						<th>City</th>
						<th>Province</th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(items) &&
						items.map(item => {
							return (
								<tr key={uid()}>
									<td>
										<div className="custom-control custom-switch">
											<input
												type="checkbox"
												className="custom-control-input"
												id={`Active_${item.id}`}
												value={item.id}
												onChange={onChange}
												checked={_some(dealerships, {
													id: item.id
												})}
											/>
											<label
												className="custom-control-label"
												htmlFor={`Active_${item.id}`}
											></label>
										</div>
									</td>
									<td>{item.ddc_id}</td>
									<td>{item.manheim_id}</td>
									<td>{item.dealer_name}</td>
									<td>{item.address}</td>
									<td>{item.city}</td>
									<td>{item.province}</td>
								</tr>
							);
						})}
				</tbody>
			</table>
		</div>
	);
};
